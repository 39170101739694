// 导入组件
import Vue from "vue";
import Router from "vue-router";
import layout from "@/layout/index";
const _importPro = (file) => () => import("@/views/" + file + ".vue");

// 启用路由
Vue.use(Router);

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
//push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  }
  return originalReplace.call(this, location).catch((err) => err);
};

// 导出路由
export default new Router({
  // mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      hidden: true,
      // 重定向
      redirect: "/contrast/create",
    },
    {
      path: "/login",
      name: "Login",
      component: _importPro("login/index"),
      hidden: true,
      meta: {
        requireAuth: false,
      },
    },
    {
      // 比对
      path: "/contrast",
      name: "contrast",
      label: "合同比对",
      component: layout,
      icon: "el-icon-tickets",
      hideMenu: true, // TODO: 临时隐藏,对于 layout的 TODO
      children: [
        {
          path: "/contrast/create",
          name: "contrastCreate",
          label: "新建合同比对",
          icon: "menu1.png",
          component: _importPro("contrast/create"),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/contrast/list",
          name: "contrastList",
          label: "合同比对结果",
          icon: "menu3.png",
          component: _importPro("contrast/list"),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/contrast/detail",
          name: "contrastDetail",
          component: _importPro("contrast/detail"),
          hidden: true,
          meta: {
            requireAuth: true,
            hideMenu: true,
            hidePadding: true,
            hideHeader: true,
          },
        },
        {
          path: "/team",
          name: "team",
          component: _importPro("team/index"),
          hidden: true,
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/member/memberBenefits",
          name: "memberBenefits",
          component: _importPro("member/memberBenefits"),
          hidden: true,
          meta: {
            requireAuth: true,
            hideMenu: true,
            hidePadding: true,
            hideHeader: true,
          },
        },
        {
          path: "/member/integralMall",
          name: "integralMall",
          component: _importPro("member/integralMall"),
          hidden: true,
          meta: {
            requireAuth: true,
            // hideMenu: true,
            // hidePadding: true,
            // hideHeader: true,
          },
        },
        {
          path: "/user/info",
          name: "userInfo",
          component: _importPro("user/userInfo"),
          hidden: true,
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
    {
      path: "/template",
      name: "template",
      component: layout,
      hideMenu: true,
      children: [
        {
          path: "/template/index",
          name: "templateCenter",
          label: "模板中心",
          icon: "menu4.png",
          component: _importPro("template/index"),
          meta: {
            requireAuth: true,
            // hideMenu: true,
            // hidePadding: true,
            // hideHeader: true,
          },
        },
        {
          path: "/template/detail/:id",
          name: "templateDetail",
          component: _importPro("template/components/TemplateDetails"),
          hidden: true,
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
  ],
});
