<template>
  <el-dialog title="团队版权益" :visible.sync="dialogVisible" width="20%" append-to-body @close="handleClose">
    <p style="text-align: center; font-weight: 600">请联系客服升级会员权益。</p>
    <p style="text-align: center; font-size: 20px; font-weight: 600">客服微信</p>
    <div class="qr-code-container">
      <img :src="qrCodeUrl" alt="服务二维码" class="qr-code-image" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "PreSalesServiceQRCodeDialog",
  data() {
    return {
      dialogVisible: false,
      qrCodeUrl: "https://lawyer-ocr-1320925085.cos.ap-guangzhou.myqcloud.com/app-static/pre-sales-service-qrcode.png",
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-code-image {
  max-width: 100%;
  height: auto;
}
</style>
