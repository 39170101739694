<template>
  <div v-if="false" @mouseleave="handleMouseLeave()">
    <div v-show="!showFullTools" class="small-circle" @mouseover="handleMouseEnter()">
      <!-- 小圆圈内容 -->
      <i class="el-icon-question"></i>
    </div>
    <div v-show="showFullTools" class="tools-box">
      <div class="tools-item" @click="handleFeedback">
        <i class="el-icon-link"></i>
        <span>反馈</span>
      </div>
      <el-popover v-if="showFullTools" class="tools-item" placement="left-start" width="188" trigger="hover">
        <div class="tools-tips">请使用微信扫描下方二维码</div>
        <img style="width: 188px; height: 188px" src="@/assets/img/service.jpg" fit="cover" />
        <div slot="reference" class="tools-item">
          <i class="el-icon-service"></i>
          <span>客服</span>
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
import { FEEDBACK_LINK } from "@/config";
export default {
  name: "Tools",
  data() {
    return {
      showFullTools: false,
    };
  },
  methods: {
    handleFeedback() {
      window.open(FEEDBACK_LINK);
    },
    handleMouseEnter() {
      this.showFullTools = true;
      const toolsBox = document.querySelector(".tools-box");
      toolsBox.classList.add("hover");
    },
    handleMouseLeave() {
      this.showFullTools = false;
      const toolsBox = document.querySelector(".tools-box");
      toolsBox.classList.remove("hover");
    },
  },
};
</script>

<style lang="scss" scoped>
// 定义通用样式

.small-circle {
  width: 40px;
  height: 40px;
  background-color: #6289f8; // 浅蓝色，透明度为 0.8
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 50px;
  right: 10px;
  cursor: pointer;
  color: white;
  z-index: 100; // 设置 z-index 值确保小圆圈在页面上方

  // 样式可以根据需求自行调整
}

.tools-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 20px 0px;
  border-radius: 40px;
  position: fixed;
  bottom: 32px;
  right: 10px;
  z-index: 99;
  .tools-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 12px;
    padding: 0 6px;
    color: #666;
    cursor: pointer;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.tools-tips {
  padding-left: 10px;
}
</style>
