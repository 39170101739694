import service from "@/api/request/baseApiService";

let request = service.buildRequest();

const user = {
  state: {
    userInfo: null,
  },
  mutations: {
    setUserInfo: (state, value) => {
      state.userInfo = value;
    },
  },
  actions: {
    async refreshUserInfo({ commit }) {
      const res = await request.get("/api/system/user/getInfo");
      if (res.code == 200) {
        commit("setUserInfo", res.data);
      }
    },
  },
};

export default user;
