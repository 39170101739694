/*
 * 全局loading使用的 store
 * @Author: huangc
 * @Date: 2023-10-23
 */

const showLoading = {
  state: {
    isShow: false,
    text: "",
  },
  mutations: {
    setShowBar: (state, value) => {
      state.isShow = value.isShow;
      state.text = value.text;
    },
  },
};

export default showLoading;
