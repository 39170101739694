<template>
  <div class="tools-list-box">
    <div class="tools-list">
      <div v-for="(tool, index) in tools" :key="index" class="tool-item" @click="handleClick(tool.click)">
        <div class="tool-icon">
          <i :class="tool.icon"></i>
        </div>
        <span>{{ tool.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToolsList",
  data() {
    return {
      tools: [
        { name: "会员权益", icon: "ri-vip-crown-2-line", click: "vip" },
        // { name: "积分商城", icon: "ri-shopping-cart-line", click: "shop" },
        { name: "个人中心", icon: "ri-map-pin-user-line", click: "user" },
        { name: "版本公告", icon: "ri-megaphone-line", click: "version" },
        { name: "绑定手机", icon: "ri-phone-line", click: "bindPhone" },
        { name: "团队码激活", icon: "ri-group-line", click: "securityCode" },
      ],
    };
  },
  methods: {
    handleClick(click) {
      this.$emit(click);
    },
  },
};
</script>

<style lang="scss" scoped>
.tools-list-box {
  width: 100%;
  height: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px !important;
  background-image: linear-gradient(180deg, #dae6fe 0%, #fff 100%) !important;
  position: relative;
}
.tools-list {
}

.tool-item {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 10px;
}
.tool-item:hover {
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  padding: 6px;
  transition: all 0.3s;
  color: #1890ff;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.tool-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #eff7ff;
  border-radius: 8px;
  margin-right: 10px;
  i {
    font-weight: 500;
    font-size: 16px;
  }
}

.tool-item span {
  font-size: 14px;
}
</style>
