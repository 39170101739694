<template>
  <div class="nav-wrap">
    <div :class="['logo-box', { 'logo-bg': aside }]">
      <img class="logo" :src="logoCollapse" alt="" />
    </div>
    <span class="system-name">
      <el-popover
        v-if="hasTeam"
        placement="bottom"
        width="350"
        trigger="click"
        popper-class="popoverStyle"
        :visible-arrow="false"
        @show="showUserPopover = true"
        @hide="showUserPopover = false"
      >
        <TeamDropdownList></TeamDropdownList>
        <div slot="reference" class="team-space-icon" style="margin-right: 20px; font-size: 14px">
          <div class="team-space-icon-text">
            <el-tag class="team-space-icon-text-tag" size="mini">{{ teamId ? "团队" : "个人" }}</el-tag>
            <span class="team-space-icon-text-name">{{ teamId ? currentTeam.teamName : userInfo.nickName }}</span>
            <img class="team-space-icon-text-arrow" :src="vipLevelIcon[Number(currentMemberLevel)]" alt="" />
          </div>
          <i class="ri-arrow-down-s-line"></i>
          <!-- {{ teamId ? currentTeam.teamName : userInfo.nickName }} -->
        </div>
      </el-popover>
    </span>
    <div class="user-box">
      <div v-if="teamId && isTeamLeader" class="qrcode-box team-manage-box" @click="openTeamSpace">
        <i class="ri-team-line" style="font-size: 16px; margin-right: 5px"></i>
        <span>团队管理</span>
      </div>
      <!-- <el-button size="small" type="primary" @click="upPoint">积分商城</el-button> -->
      <!-- <el-button id="up-btn" size="small" @click="showVipView = true">vip会员</el-button> -->
      <el-popover
        placement="bottom"
        width="200"
        trigger="hover"
        popper-class="popoverStyle"
        :visible-arrow="false"
        @show="showUserPopover = true"
        @hide="showUserPopover = false"
      >
        <WechatPublicQRCode></WechatPublicQRCode>
        <div slot="reference" class="qrcode-box" style="margin-right: 20px">
          <i class="ri-wechat-2-line"></i>
        </div>
      </el-popover>
      <el-popover
        placement="bottom"
        width="400"
        trigger="hover"
        popper-class="popoverStyle"
        :visible-arrow="false"
        @show="showUserPopover = true"
        @hide="showUserPopover = false"
      >
        <div>
          <el-image :src="miniProgramQrcode" alt="" />
        </div>
        <div slot="reference" class="qrcode-box" style="margin-right: 20px">
          <i class="ri-mini-program-line"></i>
        </div>
      </el-popover>
      <el-popover
        placement="bottom"
        width="500"
        trigger="click"
        popper-class="popoverStyle"
        :visible-arrow="false"
        @show="showUserPopover = true"
        @hide="showUserPopover = false"
      >
        <CustomerServiceWechatQR></CustomerServiceWechatQR>
        <div slot="reference" class="qrcode-box" style="margin-right: 20px">
          <i class="ri-customer-service-2-line"></i>
        </div>
      </el-popover>
      <el-popover
        placement="bottom"
        width="150"
        trigger="hover"
        popper-class="popoverStyle"
        :visible-arrow="false"
        @show="showUserPopover = true"
        @hide="showUserPopover = false"
      >
        <ToolsList
          @vip="getVipTools"
          @user="userIfo"
          @version="getVersionTools"
          @bindPhone="bindPhone"
          @securityCode="securityCode"
        ></ToolsList>
        <div slot="reference" class="qrcode-box" style="margin-right: 20px">
          <i class="ri-menu-line"></i>
        </div>
      </el-popover>
      <div v-if="!currentMemberStatus">
        <div class="pc-member-entrance" @click="openVipPayView">
          <span class="widget-button-text button-text">{{ dynamicButtonDescription }}</span>
          <div class="span-box"></div>
          <div v-if="isNewUserWithoutMembership" class="tips">首月低至¥{{ membershipPrice }}</div>
        </div>
      </div>
      <el-popover
        placement="bottom"
        width="300"
        trigger="click"
        popper-class="popoverStyle"
        :visible-arrow="false"
        @show="showUserPopover = true"
        @hide="showUserPopover = false"
      >
        <user-profile @upLevel="upLevel" @exit="exit" @userIfo="userIfo"></user-profile>
        <img slot="reference" class="el-icon-user-solid avatar" :src="avatarImageUrl" />
      </el-popover>
    </div>
    <vip-pay v-if="showVipPayView" v-model="showVipPayView" @close="showVipPayView = false"></vip-pay>
    <NoticeDialog
      ref="noticeDialog"
      :dialog-visible="noticeDialogVisible"
      :notice-data="noticeData"
      @handelClose="handelCloseNoticeDialog"
    />
    <BindPhoneDialog ref="bindPhoneDialog" />
    <SecurityCodeDialog ref="securityCodeDialog" />
  </div>
</template>
<script>
import currentMemberMixin from "@/mixins/currentMemberMixin";
import TeamDropdownList from "@/views/team/components/TeamDropdownList.vue";
import SecurityCodeDialog from "@/components/SecurityCodeDialog.vue";
import BindPhoneDialog from "@/components/BindPhoneDialog.vue";
import { delCookie } from "@/utils/util";
import { mapState } from "vuex";
import UserProfile from "./UserProfile.vue";
import VipPay from "./VipPay.vue";
import { createSocket, oncloseWS } from "@/utils/socketClientApi";
import { vipLevelStyle, vipLevelIcon, DEFAULT_VIP_LEVEL, miniProgramQrcode } from "@/config";
import CustomerServiceWechatQR from "./CustomerServiceWechatQR.vue";
import WechatPublicQRCode from "./WechatPublicQRCode.vue";
import ToolsList from "./ToolsList.vue";
import NoticeDialog from "./NoticeDialog.vue";

import store from "@/store";
export default {
  name: "Nav",
  components: {
    TeamDropdownList,
    SecurityCodeDialog,
    WechatPublicQRCode,
    BindPhoneDialog,
    NoticeDialog,
    CustomerServiceWechatQR,
    UserProfile,
    ToolsList,
    VipPay,
  },
  mixins: [currentMemberMixin],
  props: {
    aside: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      vipLevelIcon,
      miniProgramQrcode,
      showVipView: false,
      showVipPayView: false,
      showUserPopover: false,
      vipData: [],
      noticeDialogVisible: false,
      noticeData: {},
    };
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo || {},
    }),
    userLevelVO() {
      return this.userInfo.userLevelVO;
    },
    teamLevelVOs() {
      return this.userInfo.teamLevelVOs;
    },
    teamId() {
      return this.userInfo?.teamId || "";
    },
    isTeamLeader() {
      return this.currentTeam.isTeamLeader;
    },
    currentTeam() {
      return this.teamLevelVOs.find((item) => item.teamId === this.teamId) || {};
    },
    hasTeam() {
      return this.teamLevelVOs && this.teamLevelVOs.length > 0;
    },
    avatarStyle() {
      return {
        backgroundImage: `url(${store.getters.avatar})`,
      };
    },
    logoCollapse() {
      return this.aside ? require("@/assets/img/logo.png") : require("@/assets/img/logo3.png");
    },
    avatarImageUrl() {
      return this.userInfo.avatar || require("@/assets/img/avatar.png");
    },
    vipLevel() {
      return this.userInfo.userLevelVO.isExpired ? "0" : this.userInfo.userLevelVO?.level;
    },
    dynamicButtonDescription() {
      if (this.isNewUserWithoutMembership) {
        return "新人开通优惠";
      }
      if (Number(this.vipLevel) === 0) {
        return "开通高级会员";
      }
      return "续费高级会员";
    },
    isNewUserWithoutMembership() {
      if (Number(this.vipLevel) !== 0) return false;
      return this.vipData[1] && this.vipData[1].isNew;
    },
    membershipPrice() {
      if (!this.vipData || this.vipData.length === 0) return "0.00";
      const highVipData = this.vipData[Number(DEFAULT_VIP_LEVEL.HIGH_VIP)];
      if (!highVipData || !highVipData.priceList || highVipData.priceList.length === 0) return "0.00";
      const firstPrice = highVipData.priceList[0].firstPrice;
      if (firstPrice === undefined || firstPrice === null) return "0.00";
      return Number(firstPrice).toFixed(2);
    },
    memberName() {
      const { userLevelVO } = this.userInfo;
      if (
        Number(this.vipLevel) === 0 &&
        this.vipData &&
        this.vipData.length > 0 &&
        this.vipData.filter((item) => item.level !== 0).every((item) => item.isNew === true)
      ) {
        return "新人开通优惠";
      }
      return userLevelVO.memberName;
    },
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      // this.showUpBtn = to.name == "userInfo" ? true : false;
    },
  },
  created() {
    this.getMemberProduct();
  },
  mounted() {
    createSocket();
  },
  methods: {
    // 打开团队空间
    openTeamSpace() {
      this.$router.push({ path: `/team` });
    },
    securityCode() {
      this.$refs.securityCodeDialog.openDialog();
    },
    bindPhone() {
      console.log(this.$refs.bindPhoneDialog);
      this.$refs.bindPhoneDialog.openDialog();
    },
    async getVersionTools() {
      try {
        let body = {
          noticeTitle: "",
          noticeType: "",
        };
        const { code, data } = await this.commonApiService.getNotice(body);
        if (code === 200 && data && data.length > 0) {
          this.noticeData = data.at(-1);
          this.noticeDialogVisible = true;
        }
      } catch (err) {
        // 忽略异常，不做处理
      }
    },
    handelCloseNoticeDialog() {
      this.noticeDialogVisible = false;
    },
    async getMemberProduct() {
      try {
        const res = await this.commonApiService.getMemberProduct();
        if (res.data && Array.isArray(res.data)) {
          this.vipData = res.data;
        } else {
          console.log("Error: Invalid data format received.");
        }
      } catch (error) {
        console.error("Error fetching member products:", error);
      }
    },
    pointAddFn(value) {
      this.showVipView = true;
    },
    upLevel() {
      this.showVipView = true;
    },
    openVipPayView() {
      this.showVipPayView = true;
    },
    userIfo() {
      this.$router.push({
        path: `/user/info`,
      });
    },
    exit() {
      this.$confirm("退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(this.performLogout)
        .catch(() => {});
    },
    performLogout() {
      delCookie("token");
      oncloseWS();
      this.$router.push({ path: "/login" });
      this.$message({
        type: "success",
        message: "已退出登录!",
      });
    },
    getVipTools() {
      this.$router.push({
        path: `/member/memberBenefits`,
      });
    },
  },
};
</script>
<style lang="scss">
::v-deep.el-popover__reference-wrapper {
  display: flex;
  align-items: center;
}

.el-popover.popoverStyle {
  border-radius: 8px;
  background-color: #eeeeee;
  padding: 8px !important;
}
</style>
<style scoped lang="scss">
::v-deep.user-center-container {
  .personal-user-card {
    box-sizing: border-box;
    display: flex;
    height: 64px;
    margin-bottom: 8px;
    padding: 8px;
    width: 100%;
  }

  .personal-avatar {
    flex: 0 0 48px;
    height: 48px;
    margin-right: 16px;
    position: relative;
    width: 48px;

    img {
      border-radius: 50%;
      border: 2px solid #e5e7e9;
    }
  }

  .right-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    gap: 2px;

    .nickname-box {
      align-items: center;
      display: flex;
      height: 22px;
      line-height: 22px;
      margin-bottom: 2px;
      margin-top: 2px;

      .nickname {
        color: hsla(0, 0%, 5%, 0.9);
        display: inline-block;

        font-size: 14px;
        font-weight: 600;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      @keyframes shine {
        0% {
          background-position: -200px;
        }

        40%,
        100% {
          background-position: 200px;
        }
      }

      .vip-level-name {
        font-weight: 600;
        font-size: 12px;
        padding: 1px 6px;
        border-radius: 7px;
        margin-left: 4px;
        background: linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.1) 25%,
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0.1) 75%
        );
        background-size: 200px 100%;
        animation: shine 3s linear infinite;
      }

      .vip-icon {
        width: 28px;
        height: 28px;
        margin-right: 4px;
      }
    }

    .account-other {
      align-items: center;
      color: hsla(0, 0%, 5%, 0.66);
      display: flex;
      flex-wrap: nowrap;
      font-size: 12px;
      font-weight: 400;
      height: 20px;
      line-height: 20px;
      margin: 0;
    }
  }

  .exit {
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: #409eff;
      /* 或者你喜欢的颜色 */
    }
  }
}

.nav-wrap {
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: 0 4px 20px #0000000d;

  .logo-box {
    display: block;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;

    .logo {
      height: 36px;
      object-fit: contain;
    }
  }

  .logo-bg {
    padding: 0 20px;
  }

  .system-name {
    margin-left: 20px;
    .team-space-icon {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 16px;
      .team-space-icon-text {
        display: flex;
        align-items: center;
        .team-space-icon-text-arrow {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
        .team-space-icon-text-name {
          // 超过10个字符显示...
          margin-left: 4px;
          margin-right: 4px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 120px;
        }
      }
    }
  }

  .user-box {
    cursor: pointer;
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;

    .team-manage-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 30px;
      margin-right: 20px;
      width: 120px !important;
      background-image: linear-gradient(45deg, #6289f8 0%, #3366ff 99%, #eff7ff 100%) !important;
      font-weight: 500;
      color: #fff;
      font-size: 14px;
      border: 2px solid #eff7ff !important;
    }

    .qrcode-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background: #eff7ff;
      border: 1px solid #fff;
      border-radius: 8px;

      i {
        font-size: 18px;
      }
    }

    .pc-member-entrance {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 32px;
      min-width: 80px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 0 10px;
      // background: linear-gradient(to bottom right, #fdfaf4, #fff4bd);
      background-image: url("https://lawyer-ocr-1320925085.cos.ap-guangzhou.myqcloud.com/app-static/pc/vip-button-bg-img.png");
      border-radius: 8px;
      border: 1px solid #fdbe51;
      background-size: 100% 100%;
      position: relative;
      cursor: pointer;
      margin-right: 16px;

      .widget-button-text {
        background-image: linear-gradient(270deg, rgb(166, 77, 0), rgb(203, 128, 0) 50%, rgb(166, 77, 0) 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        font-size: 14px;
        font-weight: 700;
      }

      .tips {
        height: 32px;
        line-height: 32px;
        background: #ff5643;
        border-radius: 8px 8px 0 8px;
        font-size: 20px;
        color: #fff;
        letter-spacing: 0;
        padding: 0 8px;
        position: absolute;
        bottom: 29px;
        right: 0;
        -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
        transform: scale(0.5);
        -webkit-transform-origin: right bottom;
        -ms-transform-origin: right bottom;
        transform-origin: right bottom;
        white-space: nowrap;
        font-family: PingFangSC-Semibold;
        text-align: center;
        font-weight: 600;
      }
    }
  }

  .user-name {
    margin-right: 10px;
    font-size: 16px;
    color: #333;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 18px;
    border-radius: 50%;
    color: #fff;
    background-size: cover;
    background-position: center;
    border: 2px solid #e5e7e9;
  }

  #up-btn {
    background: linear-gradient(270deg, rgb(245, 210, 162) 0%, rgb(235, 189, 125) 100%);
    border: none;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.03);
    margin-right: 20px;
    font-size: 12px;
    color: #051e56;
    background-size: 200px 100%;
    animation: shine 3s linear infinite;

    .vip {
      font-weight: bold;
    }
  }
}
</style>
