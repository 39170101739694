<template>
  <el-menu :collapse="collapsed" router :default-active="$route.path" class="menu-wrap">
    <div v-for="(group, index) in menuList" :key="index">
      <el-submenu v-if="group.children.length" :index="group.path">
        <i :class="group.icon"></i>
        <span slot="title">{{ group.label }}</span>
        <el-menu-item v-for="(item, idx) in group.children" :key="`${index}_${idx}`" :index="item.path">
          <template slot="title">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="renderIcon(item.icon)"></span>
            <span>{{ item.label }}</span>
          </template>
        </el-menu-item>
      </el-submenu>
      <el-menu-item v-else :index="group.path">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="renderIcon(group.icon)"></span>
        <span slot="title">{{ group.label }}</span>
      </el-menu-item>
    </div>

    <div class="collapsed-btn">
      <el-button type="text" :icon="collapsed ? 'el-icon-s-unfold' : 'el-icon-s-fold'" @click="toggle"></el-button>
    </div>
  </el-menu>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Menu",
  data() {
    return {
      collapsed: false,
    };
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo || {},
    }),
    menuList() {
      const routes = this.$router.options.routes;
      const menuList = this.recursiveMenu(routes);
      return menuList;
    },
  },
  mounted() {},
  methods: {
    toggle(type) {
      this.collapsed = !this.collapsed;
      this.$emit("toggle", this.collapsed);
    },
    // 递归筛选出所有hidden为假的菜单，并按照树结构返回
    recursiveMenu(routes) {
      const menuList = [];
      routes.forEach((route) => {
        if (!route.hidden) {
          const menu = {
            ...route,
            children: [],
          };
          if (route.children) {
            menu.children = this.recursiveMenu(route.children);
          }
          menuList.push(menu);
        }
      });
      // TODO 1.1.0 版本先平铺成一维菜单
      const flattenMenuList = menuList.flatMap((menu) => (menu.children.length ? menu.children : [menu]));
      return flattenMenuList;
    },
    // 判断 value是否为图片，如果是就引入图片并返回，否则返回原本值
    renderIcon(value) {
      if (/\.(jpg|jpeg|png|gif|svg|webp|ico|bmp)$/i.test(value)) {
        const base64 = require(`@/assets/img/${value}`);
        return `<img src="${base64}" alt="icon" class="icon" />`;
      }
      return `<i class='${value}'></i>`;
    },
  },
};
</script>
<style scoped lang="scss">
.menu-wrap {
  background-color: #fff;
  width: 190px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  box-shadow: 4px 0 8px rgba(0, 0, 0, 0.1);

  .collapsed-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #fff;
  }

  ::v-deep {
    .el-menu-item,
    .el-submenu__title {
      height: 50px;
      line-height: 50px;

      .icon,
      img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
    }
  }
}
</style>
