export const privacy = `

信息收集：我们可能会收集您提供的信息，如姓名、电子邮件地址、电话号码等，以便向您提供服务。

信息使用：我们可能会使用您的信息来改进我们的产品和服务，进行市场推广，或遵守法律法规和政府机关的要求。

信息披露：我们承诺不会将您的个人信息出售给任何第三方。但在获得您的明确同意、法律要求或保护公司权益的情况下，我们可能会披露您的个人信息。

数据存储和安全：我们会采取合理的技术和管理措施来保护您的个人信息，防止未经授权的访问、使用或披露。但请注意，互联网上没有任何一种传输或存储方式是完全安全的。

隐私政策的修改：我们保留随时修改本政策的权利。如有重大变更，我们会在服务中发布相关公告。如您不同意修改后的本政策，您可以选择停止使用我们的服务。如您继续使用我们的服务，则视为您已接受修改后的本政策。

用户权利：您有权访问、更正、删除您的个人信息，以及撤回对我们处理您的个人信息的同意。在某些情况下，您还可以要求我们限制处理您的个人信息。如果您想行使这些权利，请与我们联系。
`;

export const userAgreement = `
一、特别提示

（一）佛山市中科天目人工智能科技有限公司同意按照本协议的规定及其不时发布的操作规则提供基于互联网以及移动网的相关服务（以下称“网络服务”），为获得网络服务，服务使用人（以下称“用户”）应当同意本协议的全部条款并按照页面上的提示完成全部的注册程序。用户在进行注册程序过程中点击“同意”按钮即表示用户完全接受本协议项下的全部条款。

（二）用户注册成功后，天目比对网站将给予每个用户一个用户账号及相应的密码，该用户账号和密码由用户负责保管；用户应当对以其用户账号进行的所有活动和事件负法律责任。

二、服务内容

（一）我们的产品或服务的具体内容由天目比对网站根据实际情况提供。

（二）天目比对网站提供的部分网络服务（例如手机图片铃声下载、电子邮件等）为收费的网络服务，用户使用收费网络服务需要向网站支付一定的费用。对于收费的网络服务，天目比对网站会在用户使用之前给予用户明确的提示，只有用户根据提示确认其愿意支付相关费用，用户才能使用该等收费网络服务。如用户拒绝支付相关费用，则网站有权不向用户提供该等收费网络服务。

（三）天目比对网站仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由用户自行负担。

三、服务变更、中断或终止

（一）鉴于网络服务的特殊性，用户同意天目比对网站有权随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。如变更、中断或终止的网络服务属于免费网络服务，天目比对网站无需通知用户，也无需对任何用户或任何第三方承担任何责任；如变更、中断或终止的网络服务属于收费网络服务，天目比对网站应当在变更、中断或终止之前事先通知用户，并应向受影响的用户提供等值的替代性的收费网络服务，如用户不愿意接受替代性的收费网络服务，就该用户已经向天目比对网站支付的服务费，网站应当按照该用户实际使用相应收费网络服务的情况扣除相应服务费之后将剩余的服务费退还给该用户。

（二）用户理解，天目比对网站需要定期或不定期地对提供网络服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护，如因此类情况而造成收费网络服务在合理时间内的中断，天目比对网站无需为此承担任何责任，但天目比对网站应尽可能事先进行通告。

（三）如发生下列任何一种情形，天目比对网站有权随时中断或终止向用户提供本协议项下的网络服务（包括收费网络服务）而无需对用户或任何第三方承担任何责任：

1、用户提供的个人资料不真实；

2、用户违反本协议中规定的使用规则；

3、用户在使用收费网络服务时未按规定向网站支付相应的服务费。

四、使用规则

（一）用户在申请使用天目比对网站网络服务时，必须向天目比对网站提供准确的个人资料，如个人资料有任何变动，必须及时更新。

（二）用户不应将其账号、密码转让或出借予他人使用。如用户发现其账号遭他人非法使用，应立即通知天目比对网站。因黑客行为或用户的保管疏忽导致账号、密码遭他人非法使用，天目比对网站不承担任何责任。

（三）对于用户通过我们的产品或服务（包括但不限于论坛、bbs、新闻评论、个人家园）上传到天目比对网站上可公开获取区域的任何内容，用户同意天目比对网站在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。

（四）用户在使用我们的产品或服务过程中，必须遵循以下原则：

1、遵守中国有关的法律和法规；

2、遵守所有与网络服务有关的网络协议、规定和程序；

3、不得为任何非法目的而使用网络服务系统；

4、不得利用我们的产品或服务系统进行任何可能对互联网或移动网正常运转造成不利影响的行为；

5、不得利用天目比对网站提供的网络服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；

6、不得侵犯其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益；

7、如发现任何非法使用用户账号或账号出现安全漏洞的情况，应立即通告天目比对网站。

（五）如用户在使用网络服务时违反任何上述规定，天目比对网站或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容等、暂停或终止用户使用网络服务的权利）以减轻用户不当行为造成的影响。

（六）天目比对网站针对某些特定的产品或服务的使用通过各种方式（包括但不限于网页公告、电子邮件、短信提醒等）作出的任何声明、通知、警示等内容视为本协议的一部分，用户如使用该等产品或服务，视为用户同意该等声明、通知、警示的内容。

五、知识产权

（一）天目比对网站提供的网络服务中包含的任何文本、图片、图形、音频和/或视频资料均受版权、商标和/或其它财产所有权法律的保护，未经相关权利人同意，上述资料均不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。所有这些资料或资料的任何部分仅可作为私人和非商业用途而保存在某台计算机内。天目比对网站不就由上述资料产生或在传送或递交全部或部分上述资料过程中产生的延误、不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，以任何形式，向用户或任何第三方负责。

（二）天目比对网站为提供网络服务而使用的任何软件（包括但不限于软件中所含的任何图象、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料）的一切权利均属于该软件的著作权人，未经该软件的著作权人许可，用户不得对该软件进行反向工程（reverse-engineer）、反向编译（decompile）或反汇编（disassemble）。

六、隐私保护

（一）保护用户隐私是天目比对网站的一项基本政策，天目比对网站保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在网站的非公开内容，但下列情况除外：

1、事先获得用户的明确授权；

2、根据有关的法律法规要求；

3、按照相关政府主管部门的要求；

4、为维护社会公众的利益；

5、为维护网站的合法权益。

（二）天目比对网站可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与天目比对网站同等的保护用户隐私的责任，则网站有权将用户的注册资料等提供给该第三方。

（三）在不透露单个用户隐私资料的前提下，天目比对网站有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。
`;

export const HELP_CENTER_DATA = [
  {
    title: "普通会员、高级会员有什么区别？",
    content: "普通会员每月会获得3次免费比对服务，而高级会员则享有不限次的比对服务",
  },
  {
    title: "比对次数会过期吗？",
    content:
      "普通会员每月赠送3次比对服务，您亦可通过每日签到获取对应积分来兑换额外的比对次数（请注意，通过积分兑换的比对次数仅当月有效）；高级会员可享受不限次比对服务，您可以随时放心使用，无需担心比对次数的限制。",
  },
  {
    title: "购买高级会员套餐后可以退货退款吗？",
    content: "您好，由于我们的产品为虚拟数字化产品，非技术故障问题，一经售出不支持退货或退款，感谢您的理解和支持",
  },
];

// 比对次数规则
export const COMPARISON_RULES = {
  title: "比对次数规则",
  content:
    "当用户开通高级会员时，比对次数不受限制。对于普通会员用户，每个月赠送三次比对机会，比对次数不累计，次月清零。",
};

// 隐私协议地址
export const PRIVACY_POLICY_URL =
  "https://lawyer-ocr-1320925085.cos.ap-guangzhou.myqcloud.com/app-static/pc/%E5%A4%A9%E7%9B%AE%E6%AF%94%E5%AF%B9%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.pdf";

// 软件服务协议地址
export const SERVICE_AGREEMENT_URL =
  "https://lawyer-ocr-1320925085.cos.ap-guangzhou.myqcloud.com/app-static/pc/%E5%A4%A9%E7%9B%AE%E6%AF%94%E5%AF%B9%E8%BD%AF%E4%BB%B6%E8%AE%B8%E5%8F%AF%E5%8F%8A%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf";

// 默认头像地址
export const DEFAULT_AVATAR_URL =
  "https://lawyer-ocr-1320925085.cos.ap-guangzhou.myqcloud.com/app-static/pc/avatar.png";

// 操作指引
export const CREATE_TABS = [
  {
    label: "操作指引",
    icon: "el-icon-copy-document",
    key: "guide",
  },
  // {
  //   label: '视频介绍',
  //   icon: 'el-icon-s-platform',
  //   disabled: true,
  // },
  {
    label: "流程介绍",
    icon: "el-icon-flow",
    key: "process",
  },
  {
    label: "应用场景",
    icon: "el-icon-copy-document",
    key: "scenario",
  },
];

// 创建对比步骤
export const CREATE_STEPS = [
  {
    title: "上传对比合同",
    //description: "上传标准合同、上传比对合同，可上传PDF、Word文件进行比对，比对合同可上传多份",
    description: "上传标准合同、比对合同，可以上传PDF、Word文件。",
  },
  {
    title: "合同比对",
    //description: "上传标准合同、上传比对合同，可上传PDF、Word文件进行比对，比对合同可上传多份",
    description: "对标准合同、比对合同进行全文比对。",
  },
  {
    title: "合同比对结果",
    //description: "上传标准合同、上传比对合同，可上传PDF、Word文件进行比对，比对合同可上传多份",
    description: "直观显示合同差异，可导出差异报告。",
  },
];

export const DIFF_TYPES = new Map([
  ["all", { label: "全部", value: "all", color: "#000000" }],
  ["modify", { label: "修改", value: "modify", color: "#0000FF" }],
  ["deleted", { label: "删除", value: "deleted", color: "#FF0000" }],
  ["added", { label: "增加", value: "added", color: "#32CD32" }],
]);

export const DETAIL_TABS = [
  {
    label: "总差异",
    value: "diff",
    classify: Array.from(DIFF_TYPES.values()),
  },
  // {
  //   label: "印章",
  //   value: "seal",
  // },
  {
    label: "忽略",
    value: "ignore",
    classify: Array.from(DIFF_TYPES.values()),
  },
];

export const CONTRACT_MAP = new Map([
  ["source", "标准合同"],
  ["contrast", "比对合同"],
]);

// 对比状态
export const COMPARISON_STATUS = {
  LOADING: 1,
  SUCCESS: 2,
  FAIL: 3,
};
// 对比状态对应的文字
export const COMPARISON_STATUS_MAP = new Map([
  [COMPARISON_STATUS.LOADING, { label: "比对中", icon: "el-icon-loading", color: "#409EFF" }],
  [COMPARISON_STATUS.SUCCESS, { label: "比对成功", icon: "el-icon-check", color: "#67C23A" }],
  [COMPARISON_STATUS.FAIL, { label: "比对失败", icon: "el-icon-close", color: "#F56C6C" }],
]);

// 对比列表排序配置
export const sortOptions = [
  {
    label: "按比对日期排序",
    value: "create_time",
  },
  {
    label: "按合同名称排序",
    value: "file_name",
  },
  {
    label: "按成功率排序",
    value: "change_rate",
  },
];

export const comparisonRuleOptions = [
  {
    value: 1,
    label: "忽略标点符号",
    icon: "el-icon-s-tools",
    key: "shieldWordSetting",
    check: true,
  },
  {
    value: 2,
    label: "忽略页眉、页脚",
    check: false,
    disabled: true,
  },
  {
    value: 3,
    label: "忽略标注",
    check: false,
    disabled: true,
  },
  {
    value: 4,
    label: "忽略印章",
    check: false,
    key: "IgnoreSeal",
    disabled: true,
  },
];

export const DEFAULT_VIP_LEVEL = {
  DEFAULT_VIP: "0",
  HIGH_VIP: "1",
  SUPER_VIP: "2",
};

// 默认的屏蔽词
export const DEFAULT_SHIELD_WORD = [
  "，",
  "。",
  "、",
  "；",
  "：",
  "？",
  "！",
  "“",
  "”",
  "‘",
  "’",
  "（",
  "）",
  "【",
  "】",
  "《",
  "》",
];

// 反馈链接
export const FEEDBACK_LINK = "https://www.wjx.cn/vm/QnTjciV.aspx#";

export const vipLevelStyle = {
  0: {
    color: "#061545 !important",
    backgroundColor: "#c9d8fd",
    animation: "none",
  },
  1: {
    color: "#5b320d !important",
    backgroundColor: "#efc68d",
  },
  2: {
    color: "#f3d6a2 !important",
    backgroundColor: "#4d78de",
  },
};

export const vipLevelIcon = {
  0: "https://lawyer-ocr-1320925085.cos.ap-guangzhou.myqcloud.com/app-static/vip/vip-level-0.svg",
  1: "https://lawyer-ocr-1320925085.cos.ap-guangzhou.myqcloud.com/app-static/vip/vip-level-1.svg",
  2: "https://lawyer-ocr-1320925085.cos.ap-guangzhou.myqcloud.com/app-static/vip/vip-level-2.svg",
};

// 小程序二维码宣传图地址
export const miniProgramQrcode =
  "https://lawyer-ocr-1320925085.cos.ap-guangzhou.myqcloud.com/app-static/pc/mini-program-qrcode.png";

// 会员功能权益描述
export const FEATURE_BENEFITS_LEVELS = [
  {
    level: 0,
    benefits: [
      {
        label: "比对次数",
        describe: "3次/每月",
      },
      {
        label: "比对合同页数",
        describe: "50页",
      },
      {
        label: "技术支持",
        describe: "普通客服",
      },
    ],
  },
  {
    level: 1,
    benefits: [
      {
        label: "比对次数",
        describe: "不限",
      },
      {
        label: "比对合同页数",
        describe: "不限",
      },
      {
        label: "技术支持",
        describe: "专属客服",
      },
    ],
  },
];

// 会员规则
export const VIP_RULES = ["1、若选择升级，升级成功高级会员有效期叠加", "2、产品属于“数字作品”，不支持7天无理由退货"];

export default {
  development: {
    phone: "18022745997",
    newContractComparisonVideoUrl: "https://cesium.com/public/SandcastleSampleData/big-buck-bunny_trailer.mp4",
    contractSpaceIndexVideoUrl: "http://vjs.zencdn.net/v/oceans.mp4",
  },
  production: {
    phone: "18022745997",
    newContractComparisonVideoUrl: "https://cesium.com/public/SandcastleSampleData/big-buck-bunny_trailer.mp4",
    contractSpaceIndexVideoUrl: "http://vjs.zencdn.net/v/oceans.mp4",
  },
}[process.env.NODE_ENV];
