<template>
  <div>
    <div v-if="type === 'card'" class="vip-card-box" :style="getVipLevelStyle">
      <el-image :src="getVipLevelIcon" class="vip-icon" />
      <div class="vip-level-name">{{ vipMemberName }}</div>
    </div>
  </div>
</template>

<script>
import { vipLevelIcon } from "@/config";
export default {
  name: "VipLevelIcon",
  props: {
    vipLevel: {
      type: String,
      default: "0",
    },
    vipMemberName: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "card",
    },
  },
  data() {
    return {
      vipLevelStyleList: {
        0: {
          color: "#061545 !important",
          backgroundColor: "rgba(201, 216, 253, 0.5)", // #c9d8fd with 50% opacity
          animation: "none",
        },
        1: {
          color: "#5b320d !important",
          backgroundColor: "rgba(239, 198, 141, 0.5)", // #efc68d with 50% opacity
        },
        2: {
          color: "#f3d6a2 !important",
          backgroundColor: "rgba(77, 120, 222, 0.5)", // #4d78de with 50% opacity
        },
      },
    };
  },
  computed: {
    getVipLevelIcon() {
      return vipLevelIcon[this.vipLevel];
    },

    getVipLevelStyle() {
      return this.vipLevelStyleList[this.vipLevel];
    },
  },
};
</script>

<style scoped>
.vip-card-box {
  display: flex;
  align-items: center;
  z-index: 0;
  font-weight: 600;
  font-size: 12px;
  padding: 0px 8px 0px 1px;
  border-radius: 8px;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1) 25%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.1) 75%
  );
  background-size: 200px 100%;
  animation: shine 3s linear infinite;

  @keyframes shine {
    0% {
      background-position: -200px;
    }

    40%,
    100% {
      background-position: 200px;
    }
  }
  .vip-icon {
    width: 20px;
    height: 20px;
    margin-right: 2px;
    transform: scale(1.2);
  }
}
</style>
