import service from "./request/baseApiService";

let request = service.buildRequest();
let fileRequest = service.buildFileRequest();
let jsonRequest = service.buildJsonRequest();
let dowLoadRequest = service.buildDowLoadRequest();
let request2 = service.buildRequest2();

const commonBussService = (function () {
  /**
   * 上传文件
   */
  const fileUpload = (file, config = {}) => {
    // 使用FormData对象上传文件
    const formData = new FormData();
    formData.append("file", file.raw);
    if (!file.fileRecordId) {
      return fileRequest.post("/api/system/file/fileUpload", formData, config);
    }
    return fileRequest.post(`/api/system/file/fileUpload?fileRecordId=${file.fileRecordId}`, formData, config);
  };

  /**
   * 模板下载
   * @param {Object} params
   * @returns
   */
  const templateDownload = (templateId) => {
    dowLoadRequest
      .get(`/api/system/template/doc/download/${templateId}`, {
        responseType: "blob",
      })
      .then((response) => {
        // 处理成功响应
        let contentDisposition = decodeURIComponent(response.headers["content-disposition"]);
        // 使用正则表达式提取filename的值
        const filenameMatch = contentDisposition.match(/filename=(.*?)(;|$)/);
        const defaultFileName = `file_${templateId}.ext`; // 替换为实际的文件名和扩展名

        const fileName = filenameMatch ? filenameMatch[1] : defaultFileName;

        // 从 Content-Type 中获取文件类型
        const contentTypeHeader = response.headers["content-type"];
        const fileType = contentTypeHeader || "application/octet-stream"; // 默认为二进制流

        // 创建Blob对象
        const blob = new Blob([response.data], { type: fileType });

        // 创建下载链接
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        // 模拟用户点击以下载文件
        link.click();
      })
      .catch((error) => {
        // 处理错误
        console.error("Error downloading file:", error);
      });
  };

  /**
   * 文件下载
   * @param {*} fileId
   * @returns
   */
  const fileDownload = (fileId) => {
    dowLoadRequest
      .get(`/api/system/file/download?fileId=${fileId}`, {
        responseType: "blob",
      })
      .then((response) => {
        // 处理成功响应
        let contentDisposition = decodeURIComponent(response.headers["content-disposition"]);
        // 使用正则表达式提取filename的值
        const filenameMatch = contentDisposition.match(/filename=(.*?)(;|$)/);
        const defaultFileName = `file_${fileId}.ext`; // 替换为实际的文件名和扩展名

        const fileName = filenameMatch ? filenameMatch[1] : defaultFileName;

        // 从 Content-Type 中获取文件类型
        const contentTypeHeader = response.headers["content-type"];
        const fileType = contentTypeHeader || "application/octet-stream"; // 默认为二进制流

        // 创建Blob对象
        const blob = new Blob([response.data], { type: fileType });

        // 创建下载链接
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        // 模拟用户点击以下载文件
        link.click();
      })
      .catch((error) => {
        // 处理错误
        console.error("Error downloading file:", error);
      });
  };

  /**
   * 纠错
   */
  const correction = (params) => {
    return request.post("/api/system/contrastReport/correction", params);
  };

  /**
   * 获取字典数据
   */
  const getDictData = (dictName) => {
    return request.get(`/api/system/dict/data/getDictType/${dictName}`);
  };

  /**
   * 合同比对
   */
  const contractComparison = (params) => {
    return request.post("/api/system/contrastReport/saveContrastReport", params);
  };

  /**
   * 比对列表查询
   */
  const listContrastReport = (params) => {
    return request.post(
      `/api/system/contrastReport/listContrastReport?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
      params.body
    );
  };

  /**
   * 获取对比结果
   */
  const getContrastInfo = (id) => {
    return request.post(`/api/system/contrastReport/getContrastInfo/${id}`);
  };

  /**
   * 对比报告/查询状态
   */
  const getState = (id) => {
    return request.post(`/api/system/contrastReport/getState/${id}`);
  };

  /**
   * 对比报告/删除对比记录
   */
  const contrastReportDelete = (params) => {
    return request.post("/api/system/contrastReport/delete", params);
  };

  /**
   * 对比报告/导出差异文档
   */
  const contrastReportExport = (id) => {
    return dowLoadRequest.post(`/api/system/contrastReport/export?id=${id}`);
  };

  /**
   * 对比报告/重新比对
   */
  const reContrastReport = (reportId) => {
    return request.post(`/api/system/contrastReport/reContrastReport?reportId=${reportId}`);
  };

  /**
   * 对比报告/获取比对文件预览地址
   */
  const getContrastFile = (id) => {
    return request.post(`/api/system/contrastReport/getContrastFile?id=${id}`);
  };

  /**
   * 对比报告/新增对比报告评价
   */
  const evaluate = (params) => {
    return request.post("/api//system/evaluate", params);
  };

  /**
   * 获取差异明细
   */
  const getDifferInfo = (url) => {
    return jsonRequest.get(`${url}`);
  };

  /**
   * 空间内容/获取用户容量信息
   */
  const getSpaceInfo = (id) => {
    return request.post("/api/system/space/getSpaceInfo");
  };

  /**
   * 空间内容/合同夹列表
   */
  const getContentList = (params) => {
    return request.post(
      `/api/system/content/getContentList?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
      params.body
    );
  };

  /**
   * 空间内容/新建合同夹
   */
  const addFolder = (params) => {
    return request.post(`/api/system/content/addFolder?name=${params.name}&parentId=${params.parentId}`);
  };

  /**
   * 空间内容/保存文件到合同空间
   */
  const addFile = (params) => {
    return request.post("/api/system/content/addFile", params);
  };

  /**
   * 合同空间/空间内容/删除
   */
  const deleteContent = (id) => {
    return request.post(`/api/system/content/delete?id=${id}`);
  };

  /**
   *  合同空间/空间内容/编辑
   *  重命名
   */
  const rename = (params) => {
    return request.post("/api/system/content/edit", params);
  };

  /**
   *  后端登陆/获取验证码
   */
  const captchaImage = () => {
    return request.get("/api/captchaImage");
  };

  /**
   *  后端登陆/账号密码登陆
   */
  const login = (params) => {
    return request.post("/api/login", params);
  };

  /**
   *  读取txt内容
   */
  const getTxtContent = (url) => {
    return jsonRequest.get(url);
  };

  /**
   *  /登陆/微信公众号登陆/公众号登录二维码获取
   */
  const getWxMpQrCode = () => {
    return request.post("/api/system/user/miniProgram/getWxMpQrCode");
  };

  /**
   *  /登陆/微信公众号登陆/检查是否关注登陆
   */
  const wxMpLogin = (sceneStr) => {
    return request2.post(`/api/system/user/miniProgram/wxMpLogin?sceneStr=${sceneStr}`);
  };

  /**
   *  /登陆/微信登陆/获取用户信息
   */
  const getUserInfo = () => {
    return request.get("/api/system/user/getInfo");
  };

  /**
   *  /申请/新增申请(当邀请码为空，则为申请，需要进行审批，当输入邀请码，不走审批流)
   */
  const userApply = (params) => {
    return request.post("/api/system/apply/userApply", params);
  };

  /**
   * 查询个人屏蔽词列表
   * @param {*} params
   */
  const getWordList = () => {
    return request.post(`/api/system/word/list?pageNum=${1}&pageSize=${99999}`, {});
  };

  /**
   * 批量增加屏蔽词
   * @param {*} params
   */
  const batchAddWord = (maskWord) => {
    return request.post(`/api/system/word/saveBatch`, maskWord);
  };

  /**
   * 删除屏蔽词
   * @param {Array} works
   */
  const deleteWord = (words) => {
    return request.post(`/api/system/word/delete`, words);
  };

  const getEvaluateByReportId = (id) => {
    return request.get(`/api/system/evaluate/${id}`);
  };

  const addEvaluate = (body) => {
    return request.post("/api/system/evaluate", body);
  };
  const getNotice = (body) => {
    return request.post("/api/system/notice/getNotice", body);
  };

  /**
   * /会员体系/签到
   * @param {Array} works
   */
  const sign = () => {
    return request.get("/api/system/record/sign");
  };

  /**
   * 获取签到列表
   */
  const getSignList = (params) => {
    return request.get("/api/system/record/list", { params });
  };

  /**
   *
   * /会员体系/积分消费历史-分页
   * @param {Array} works
   */
  const userPointHistory = (params) => {
    return request.get(`/api/system/history/userPointHistory`, { params });
  };

  /**
   * /会员体系/获取会员等级价格
   * @param {Array} works
   */
  const getMemberProduct = () => {
    return request.post("/api/system/member_level/getMemberProduct");
  };

  /**
   * /会员体系/开通 vip
   * @param {Array} works
   */
  const activateNowVIP = (params) => {
    return request.post("/api/system/member_level/vip", params);
  };

  /**
   * /用户信息/更新用户信息
   * @param {Array} works
   */
  const userInfoUpdate = (params) => {
    return request.post("/api/system/user/update", params);
  };

  /**
   * 头像上传
   * @param {Object} params
   * @returns
   */
  const uploadAvatar = (params) => {
    return request.post("/api/system/user/uploadAvatar", params);
  };

  /**
   * /获取积分充值套餐
   * @param {Array} works
   */
  const getRecharge = () => {
    return request.post("/api/system/rules/getRecharge");
  };

  /**
   * 充值积分
   * @param {Object} params
   * @returns
   */
  const recharge = (params) => {
    return request.post("/api/system/rules/recharge", params);
  };

  /**
   * 忽略
   * @param {Object} params
   * @returns
   */
  const ignore = (params) => {
    return request.post("/api/system/contrastReport/ignore", params);
  };

  /**
   * 获取积分兑换的商品列表
   * @param {Object} params
   * @returns
   */
  const getProductList = (params) => {
    return request.get(`/api/system/product/getProduct?pageNum=${params.pageNum}&pageSize=${params.pageSize}`);
  };
  /**
   * 兑换商品
   * @param {Object} params
   * @returns
   */
  const purchaseProduct = (params) => {
    return request.post(`/api/system/product/purchase`, params);
  };

  /**
   * 获取用户订单记录
   * @param {Object} params
   * @returns
   */
  const getPaymentList = (params) => {
    return request.get(
      `/api/system/order/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}&userId=${params.userId}`
    );
  };

  /**
   * 获取团队成员列表
   * @returns
   */
  const getTeamUserList = (params) => {
    return request.get(
      `/api/system/teamUser/teamUserList?pageNum=${params.pageNum}&pageSize=${params.pageSize}&teamId=${params.teamId}`
    );
  };

  /**
   * 获取邀请码列表
   * @param {Object} params
   * @returns
   */
  const getInviteCodeList = (params) => {
    return request.get(
      `/api/system/teamInvite/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}&teamId=${params.teamId}`
    );
  };

  /**
   * 生成邀请码
   * @param {Object} params
   * @returns
   */
  const createTeamInvite = (params) => {
    return request.post(`/api/system/teamInvite/createTeamInvite?teamId=${params.teamId}`, {});
  };

  /**
   * 获取团队详情信息
   * @param {Object} params
   * @returns
   */
  const getTeamDetail = (params) => {
    return request.get(`/api/system/team/${params.teamId}`);
  };

  /**
   * 获取团队邀请码详情
   * @param {Object} params
   * @returns
   */
  const getTeamInviteDetail = (id) => {
    return request.get(`/api/system/teamInvite/${id}`);
  };

  /**
   * 提交邀请码加入团队
   * @param {Object} params
   * @returns
   */
  const joinTeamByCode = (params) => {
    return request.post(`/api/system/teamInvite/joinTeam?inviteCode=${params.inviteCode}`, {});
  };

  /**
   * 切换团队
   * @param {Object} params
   * @returns
   */
  const changeTeam = (teamId) => {
    return request.post(`/api/system/user/changeTeam?teamId=${teamId}`);
  };

  /**
   * 获取模板分类列表
   * @returns
   */
  const getTagCategoriesList = () => {
    return request.get("/api/system/template/categories/categoriesTree");
  };

  /**
   * 查询模板商城文档模板列表
   * @param {Object} params
   * @returns
   */
  const getStoreTemplateList = (pageData, params) => {
    return request.post(
      `/api/system/template/doc/page?pageNum=${pageData.pageNum}&pageSize=${pageData.pageSize}`,
      params
    );
  };

  /**
   * 获取模板详情
   * @param {Object} params
   * @returns
   */
  const getTemplateDetail = (params) => {
    return request.get(`/api/system/template/doc/${params.templateId}`);
  };

  /**
   * 获取个人模板列表
   * @param {Object} params
   * @returns
   */
  const getPersonalTemplateList = (pageData, params) => {
    return request.post(
      `/api/system/template/doc/userPage?pageNum=${pageData.pageNum}&pageSize=${pageData.pageSize}`,
      params
    );
  };

  /**
   * 提交模板
   */
  const submitTemplate = (params) => {
    return request.post("/api/system/template/templates/userAdd", params);
  };

  /**
   * 获取文件下载地址
   * @param {String} id
   * @returns
   */
  const getDownloadUrl = (id) => {
    return request.get(`/api/system/file/getUrl?fileId=${id}`);
  };

  /**
   * 收藏模板
   * @param {Object} params
   * @returns
   */
  const collectTemplate = (templateId) => {
    return request.post("/api/system/template/doc/collect", { templateId });
  };

  /**
   * 删除个人模板
   * @param {Object} params
   * @returns
   */
  const deletePersonalTemplate = (templateId) => {
    return request.delete(`/api/system/template/templates/${templateId}`);
  };

  /**
   * 模板购买
   * @param {Object} params
   * @returns
   */
  const buyTemplate = (params) => {
    return request.post("/api/system/template/doc/buy", params);
  };

  /**
   * 获取模板购买权限
   * @param {Object} params
   * @returns
   */
  const getTemplateBuyAuth = (templateId) => {
    return request.post(`/api/system/template/doc/canDownload/${templateId}`);
  };

  /**
   * 获取订单列表
   * @param {Object} params
   * @returns
   */
  const getOrderList = (params) => {
    return request.get(`/api/system/order/list`, { params });
  };

  return {
    getOrderList,
    getTemplateBuyAuth,
    buyTemplate,
    deletePersonalTemplate,
    collectTemplate,
    getDownloadUrl,
    submitTemplate,
    getPersonalTemplateList,
    templateDownload,
    getTemplateDetail,
    getStoreTemplateList,
    getTagCategoriesList,
    changeTeam,
    joinTeamByCode,
    getTeamInviteDetail,
    getTeamDetail,
    getInviteCodeList,
    createTeamInvite,
    getTeamUserList,
    getPaymentList,
    purchaseProduct,
    getProductList,
    correction,
    fileUpload,
    fileDownload,
    contractComparison,
    listContrastReport,
    getContrastInfo,
    getState,
    contrastReportDelete,
    contrastReportExport,
    reContrastReport,
    getContrastFile,
    evaluate,
    getDifferInfo,
    getSpaceInfo,
    getContentList,
    addFolder,
    addFile,
    deleteContent,
    rename,
    captchaImage,
    login,
    getTxtContent,
    getWxMpQrCode,
    wxMpLogin,
    getUserInfo,
    userApply,
    getWordList,
    batchAddWord,
    deleteWord,
    getEvaluateByReportId,
    addEvaluate,
    getNotice,
    sign,
    getSignList,
    userPointHistory,
    getMemberProduct,
    userInfoUpdate,
    activateNowVIP,
    uploadAvatar,
    getRecharge,
    recharge,
    ignore,
    getDictData,
  };
})();

export default commonBussService;
