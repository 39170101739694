import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo || {},
    }),
    teamId() {
      return this.userInfo.teamId;
    },
    currentMemberInfo() {
      const userInfo = this.userInfo; // 假设 userInfo 是从某处获取的
      if (!userInfo) {
        return null;
      }
      if (userInfo.teamId && userInfo.teamLevelVOs) {
        // 如果有 teamId, 从 teamLevelVOs 中找到对应的成员信息
        return userInfo.teamLevelVOs.find((vo) => vo.teamId === userInfo.teamId);
      } else if (userInfo.userLevelVO) {
        // 如果没有 teamId 但有 userLevelVO
        return userInfo.userLevelVO;
      }
      return null;
    },
    currentMemberInfoName() {
      return this.teamId ? this.currentMemberInfo.teamName : this.userInfo.nickName;
    },
    currentMemberLevel() {
      return this.currentMemberInfo.isExpired ? "0" : this.currentMemberInfo.level;
    },
    currentMemberLevelName() {
      return this.currentMemberInfo.isExpired ? "普通会员" : this.currentMemberInfo.memberName;
    },
    currentMemberStatus() {
      return this.userInfo.teamId ? 1 : 0;
    },
  },
};
