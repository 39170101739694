<template>
  <el-container class="page-wrap">
    <el-header class="page-header" :style="{ height: hideHeader ? '50px' : '0px' }">
      <layout-nav v-if="hideHeader" :aside="!closeAside"></layout-nav>
    </el-header>
    <el-container>
      <el-aside
        v-if="hideMenu"
        style="border-right: 1px solid rgb(230, 230, 230)"
        :width="closeAside ? '59px' : '189px'"
      >
        <layout-menu @toggle="toggleMenu" />
      </el-aside>
      <el-main
        style="padding: 0"
        :class="['container-wrap', { 'full-view-wrap': hidePadding }]"
        :style="containerWrapStyle"
      >
        <router-view></router-view>
        <tools />
      </el-main>
    </el-container>
    <invitation-code v-if="showInvitationCode" v-model="showInvitationCode" />
    <invitation-code v-if="showInvitationCode" v-model="showInvitationCode" />
  </el-container>
</template>

<script>
import LayoutMenu from "./components/Menu";
import LayoutNav from "./components/Nav";
import Tools from "./components/Tools.vue";
import NoticeDialog from "./components/NoticeDialog.vue";
import { getStore, setStore } from "../utils/util";
import InvitationCode from "./components/InvitationCode.vue";
export default {
  name: "Index",
  components: {
    LayoutMenu,
    LayoutNav,
    Tools,
    InvitationCode,
  },
  data() {
    return {
      closeAside: false,
    };
  },
  computed: {
    containerWrapStyle() {
      return {
        height: this.hideHeader ? "calc(100vh - 50px)" : "100vh",
      };
    },
    hidePadding() {
      return this.$route.meta.hidePadding;
    },
    hideMenu() {
      return !this.$route.meta.hideMenu;
    },
    hideHeader() {
      return !this.$route.meta.hideHeader;
    },
    showInvitationCode() {
      //const wxUse = this.$store.getters.wxUse;
      //return wxUse != 2;
      return false;
    },
  },
  mounted() {},
  methods: {
    toggleMenu(value) {
      setTimeout(() => {
        this.closeAside = value;
      }, 200);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .menu-wrap {
    border-right: 1px solid rgb(230, 230, 230) !important;
  }
}

.page-wrap {
  background-color: #f6f6f6;
  height: 100vh;

  .page-header {
    z-index: 99;
    background-color: #fff;
    // height: 60px !important;
    padding: 0;
  }

  .container-wrap {
    padding: 20px;
    overflow: auto;
    position: relative;
  }

  .full-view-wrap {
    padding: 0;
  }
}

::v-deep {
  .el-aside {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}
</style>
