<template>
  <div class="user-center-container">
    <div class="personal-user-card">
      <div class="personal-avatar">
        <img :src="avatarImageUrl" class="el-icon-user-solid avatar" style="width: 50px; height: 50px" />
      </div>
      <div class="right-wrapper">
        <div class="nickname-box">
          <img :src="getVipLevelIcon" class="vip-icon" />
          <el-tooltip
            class="item"
            effect="dark"
            :content="userInfo.nickName"
            placement="bottom"
            :disabled="userInfo.nickName.length < 10"
          >
            <span tabindex="0" class="nickname" style="max-width: 80px">{{ userInfo.nickName }}</span>
          </el-tooltip>
          <span class="vip-level-name" :style="getVipLevelStyle">{{ currentMemberLevelName || "" }}</span>
          <el-tag v-if="currentMemberStatus" size="mini">团队</el-tag>
        </div>
        <p v-if="currentMemberStatus" class="account-other">当前使用团队{{ currentMemberLevelName }}权益</p>
        <p v-else class="account-other">创建日期：{{ userInfo.createTime }}</p>
      </div>
    </div>
    <div :class="`member-bg-${currentMemberLevel}`">
      <div class="member-content-container">
        <div class="left-content">
          <div class="member-info" :class="`member-info-${currentMemberLevel}`">
            <span style="font-size: 18px; font-weight: 600">{{ currentMemberLevelName || "" }}</span>
            <span class="vip-expire-time" style="font-size: 12px; font-weight: 400">{{
              formatISODateTimeToDateString(currentMemberInfo.expireTime)
            }}</span>
          </div>
        </div>
        <div class="right-content">
          <el-button :size="$defaultSize" class="upgrade-btn" type="primary" round @click="toMemberBenefits()"
            >升级权益</el-button
          >
        </div>
        <img :src="getVipLevelIcon" class="vip-icon" />
      </div>
    </div>
    <div v-if="!currentMemberStatus" class="user-member-box">
      <div class="user-member-box-1">
        <div class="user-member-box-1-left user-member-card-50">
          <div class="title" style="justify-content: start">
            <span style="margin-right: 4px">会员权益</span>
            <el-popover
              placement="top-start"
              :title="comparisonRules.title"
              width="200"
              trigger="hover"
              :content="comparisonRules.content"
            >
              <i slot="reference" class="el-icon-question"></i>
            </el-popover>
          </div>
          <span class="content">智能比对：{{ vipLevel > 0 ? "不限" : userInfo.contrastTimes }}</span>
        </div>
        <div class="user-member-box-1-right user-member-card-50">
          <div class="title">
            <span>积分</span>
            <el-link type="primary" @click="upPoint">积分商城<i class="ri-arrow-right-s-line"></i></el-link>
          </div>
          <span class="content">个人积分：{{ userInfo.point || 0 }}</span>
        </div>
      </div>
    </div>
    <div v-if="!currentMemberStatus" class="user-member-box">
      <div class="user-member-card-100">
        <sign-in />
      </div>
    </div>
    <el-divider></el-divider>
    <div class="foot-box">
      <span class="exit" @click="$emit('exit')">退出登录</span>
      <el-divider direction="vertical"></el-divider>
      <span class="exit" @click="$emit('userIfo')">个人信息</span>
    </div>
    <vip-pay v-if="showVipPayView" v-model="showVipPayView" @close="showVipPayView = false"></vip-pay>
    <pointAdd v-if="showPointView" :show-point-view="showPointView" @close="showPointView = false"></pointAdd>
    <preSalesServiceQRCodeDialog ref="preSalesServiceQRCodeDialog" />
  </div>
</template>

<script>
import PreSalesServiceQRCodeDialog from "@/components/PreSalesServiceQRCodeDialog.vue";
import currentMemberMixin from "@/mixins/currentMemberMixin";
import { mapState } from "vuex";
import SignIn from "./SignIn.vue";
import VipPay from "./VipPay.vue";
import PointAdd from "./PointAdd.vue";
import { vipLevelStyle, vipLevelIcon, COMPARISON_RULES, DEFAULT_AVATAR_URL } from "@/config";
export default {
  name: "UserProfile",
  components: { SignIn, VipPay, PointAdd, PreSalesServiceQRCodeDialog },
  mixins: [currentMemberMixin],
  data() {
    return {
      showPointView: false,
      showVipPayView: false,
      comparisonRules: COMPARISON_RULES,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo || {},
    }),
    avatarImageUrl() {
      return this.userInfo.avatar || DEFAULT_AVATAR_URL;
    },
    vipLevel() {
      return this.userInfo.userLevelVO.isExpired ? 0 : this.userInfo.userLevelVO?.level;
    },
    vipMemberName() {
      return this.vipLevel > 0 ? this.userInfo.userLevelVO?.memberName : "普通会员";
    },
    getVipLevelStyle() {
      return vipLevelStyle[Number(this.currentMemberLevel)];
    },
    getVipLevelIcon() {
      return vipLevelIcon[Number(this.currentMemberLevel)];
    },
  },
  mounted() {
    console.log(this.userInfo);
  },
  methods: {
    toMemberBenefits() {
      if (this.teamId) {
        this.$refs.preSalesServiceQRCodeDialog.open();
      } else {
        this.showVipPayView = true;
      }
    },
    formatISODateTimeToDateString(isoDateTime) {
      if (!isoDateTime || this.currentMemberInfo.isExpired) {
        return "开通高级会员享更多权益";
      }
      const date = new Date(isoDateTime);
      const options = { year: "numeric", month: "long", day: "numeric" }; // 定义日期格式
      return `${date.toLocaleDateString("zh-CN", options)}到期`;
    },
    upLevel() {
      this.$emit("upLevel");
    },
    upPoint() {
      this.showPointView = true;
      // this.$router.push({
      //   path: `/member/integralMall`,
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.user-center-container {
  @keyframes shine {
    0% {
      background-position: -200px;
    }

    40%,
    100% {
      background-position: 200px;
    }
  }

  .vip-level-name {
    font-weight: 600;
    font-size: 16px;
    padding: 4px 8px;
    border-radius: 8px;
    margin-right: 6px;

    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.1) 25%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0.1) 75%
    );
    background-size: 200px 100%;
    animation: shine 3s linear infinite;
  }

  .personal-user-card {
    box-sizing: border-box;
    display: flex;
    height: 64px;
    margin-bottom: 8px;
    padding: 8px;
    width: 100%;
  }

  .personal-avatar {
    flex: 0 0 48px;
    height: 48px;
    margin-right: 16px;
    position: relative;
    width: 48px;

    img {
      border-radius: 50%;
      border: 2px solid #e5e7e9;
    }
  }

  .member-bg-0,
  .member-bg-1,
  .member-bg-2 {
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.03);
    overflow: hidden;
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }

  .member-bg-0 {
    background: linear-gradient(270deg, rgb(191, 206, 253) 0%, rgb(141, 170, 250) 100%);
    height: 100%;
    box-sizing: border-box;
  }

  .member-bg-1 {
    background: linear-gradient(270deg, rgb(245, 210, 162) 0%, rgb(235, 189, 125) 100%);
    height: 100%;
    box-sizing: border-box;
  }

  .member-bg-2 {
    background: linear-gradient(270deg, rgb(77, 120, 222) 0%, rgb(52, 78, 194) 100%);
    height: 100%;
    box-sizing: border-box;
  }

  .member-content-container {
    position: relative;
    overflow: hidden;
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .vip-icon {
      position: absolute;
      right: -30px;
      bottom: -40px;
      width: 200px;
      height: 200px;
    }

    .left-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .member-info {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 600;

        .vip-expire-time {
          font-size: 12px;
          margin-top: 4px;
          font-weight: 400;
        }
      }

      .member-info-0 {
        color: #061545;
      }

      .member-info-1 {
        color: #603008;
      }

      .member-info-2 {
        color: #f2c98f;
      }
    }
    .right-content {
      .upgrade-btn {
        background-color: black;
        border: none;
        color: bisque;
        position: relative;
        z-index: 999;
      }
    }
  }

  .right-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    gap: 2px;

    .nickname-box {
      align-items: center;
      display: flex;
      height: 22px;
      line-height: 22px;
      margin-bottom: 2px;
      margin-top: 2px;

      .nickname {
        color: hsla(0, 0%, 5%, 0.9);
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .vip-icon {
        width: 28px;
        height: 28px;
        margin-right: 4px;
      }
    }

    .account-other {
      align-items: center;
      color: hsla(0, 0%, 5%, 0.66);
      display: flex;
      flex-wrap: nowrap;
      font-size: 12px;
      font-weight: 400;
      height: 20px;
      line-height: 20px;
      margin: 0;
    }
  }

  .user-member-box {
    margin-top: 8px;

    .user-member-card-100 {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.03);
      flex: 1;
    }

    .user-member-card-50 {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.03);
      flex: 1;

      .title {
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .content {
        font-size: 12px;
      }
    }

    .user-member-box-1 {
      display: flex;
      justify-content: space-between;

      .user-member-box-1-left {
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        padding: 10px;
      }

      .user-member-box-1-right {
        display: flex;
        flex-direction: column;
        padding: 10px;
      }
    }
  }

  .foot-box {
    display: flex;
    justify-content: center;
    align-items: center;

    .exit {
      color: #333;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: #409eff;
        /* 或者你喜欢的颜色 */
      }
    }
  }
}
</style>
