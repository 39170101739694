import { Message } from "element-ui";
import { Notification } from "element-ui";

export default {
  install: function (Vue, opt) {
    // 开启loading
    Vue.prototype.openLoading = function (text) {
      let showLoading = {
        isShow: true,
        text: text,
      };
      this.$store.commit("setShowBar", showLoading);
    };

    // 关闭loading
    Vue.prototype.closeLoading = function () {
      let showLoading = {
        isShow: false,
        text: "",
      };
      this.$store.commit("setShowBar", showLoading);
    };

    //
    Vue.prototype.showErrorMsg = function (msg) {
      Message({
        message: msg,
        duration: 2000,
        type: "error",
      });
    };
    Vue.prototype.showSuccessMsg = function (msg, title = "成功") {
      Notification({
        title: title,
        message: msg,
        type: "success",
      });
    };
    Vue.prototype.showInfoMsg = function (msg, title = "提示") {
      Notification({
        title: title,
        message: msg,
        type: "info",
      });
    };
  },
};
