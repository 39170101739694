<template>
  <el-dialog width="30%" top="30vh" :visible="dialogVisible" append-to-body modal-append-to-body @close="handelClose">
    <div class="notice-modal-box">
      <div class="title">{{ noticeData.noticeTitle || "" }}</div>
      <div class="notice-modal-center">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="noticeData.noticeContent"></div>
      </div>
      <img class="background" src="@/assets/img/logo.svg" alt="" />
    </div>
    <!-- <span slot="footer" class="dialog-footer">
            <el-button type="primary" round @click="handelClose">已知晓</el-button>
        </span> -->
  </el-dialog>
</template>

<script>
export default {
  name: "NoticeDialog",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    noticeData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    handelClose() {
      this.$emit("handelClose");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    border-radius: 8px !important;
    background-image: linear-gradient(180deg, #dae6fe 0%, #fff 100%) !important;
  }

  .el-dialog__body {
    padding: 0 !important;
  }

  .el-dialog__footer {
    text-align: center !important;
  }
}

.notice-modal-box {
  z-index: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow-y: auto;
  position: relative;
  background-image: "linear-gradient(180deg, #dae6fe 0%, #fff 100%)" !important;

  .title {
    display: flex;
    align-items: center;
    font-size: 40px;
    font-weight: 700;
    height: 10%;
  }

  .notice-modal-center {
    z-index: 999999;
    width: 100%;
    height: 300px;
    padding: 20px;
    border-radius: 10px;
    overflow-y: auto;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .background {
    z-index: -1;
    position: absolute;
    width: 150px;
    height: 150px;
    top: 10px;
    right: 10px;
  }

  .foot {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
