import axios from "axios";
import { Message } from "element-ui";
import { getCookie, delCookie } from "../../utils/util";
import router from "../../router";

// 超时时间
const TIME_OUT = 120 * 1000;

// 业务层的统一处理
const resolveResult = (response) => {
  let errMsg = response.data.msg || "遇到未知异常";
  if (response.data && response.data.code === 200) {
    return response.data;
  }
  if (response.data.code === 500) {
    errorMsg(errMsg);
    return response.data;
  }
  if (response.data.code === 401) {
    delCookie("token");
    router.push({
      path: "/login",
    });
    return response.data;
  }
  if (response.data.code === 601) {
    return response.data;
  }
  if (response.data.code === 4001) {
    // errorMsg(errMsg);
    return response.data;
  }
  errorMsg(errMsg);
  return response.data;
};

// 网络层的统一处理
const handleError = (e) => {
  if (axios.isCancel(e)) {
    errorMsg(e.message);
    return;
  }
  let errMsg = "网络异常";
  console.warn(e);
  errorMsg(errMsg);
};

const buildRequest = () => {
  let request = axios.create({
    timeout: TIME_OUT,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: getCookie("token"),
    },
  });
  request.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = getCookie("token");
      return config;
    },
    (error) => {
      // do something with request error
      console.log(error); // for debug
      return Promise.reject(error);
    }
  );
  request.interceptors.response.use(resolveResult, handleError);
  return request;
};

const buildFileRequest = () => {
  let request = axios.create({
    timeout: TIME_OUT,
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: getCookie("token"),
    },
  });

  request.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = getCookie("token");
      return config;
    },
    (error) => {
      // do something with request error
      console.log(error); // for debug
      return Promise.reject(error);
    }
  );
  request.interceptors.response.use(resolveResult, handleError);
  return request;
};

const buildJsonRequest = () => {
  let request = axios.create({
    timeout: TIME_OUT,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
  return request;
};

const buildDowLoadRequest = () => {
  let request = axios.create({
    timeout: TIME_OUT,
    withCredentials: true,
    //responseType: "arraybuffer",
    responseType: "blob",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: getCookie("token"),
    },
  });
  request.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = getCookie("token");
      return config;
    },
    (error) => {
      // do something with request error
      console.log(error); // for debug
      return Promise.reject(error);
    }
  );
  return request;
};

const buildRequest2 = () => {
  let request = axios.create({
    timeout: TIME_OUT,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: getCookie("token"),
    },
  });
  request.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = getCookie("token");
      return config;
    },
    (error) => {
      // do something with request error
      console.log(error); // for debug
      return Promise.reject(error);
    }
  );
  return request;
};

function errorMsg(errMsg) {
  Message({
    message: errMsg,
    duration: 3000,
    type: "error",
  });
}

export default {
  buildRequest,
  buildFileRequest,
  buildJsonRequest,
  buildDowLoadRequest,
  buildRequest2,
};
