<template>
  <el-dialog
    append-to-body
    modal-append-to-body
    :visible.sync="dialogVisible"
    width="30%"
    :show-close="false"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <div class="dialog-modal-box">
      <div class="vip-tool-box-header title">
        <h2>团队激活码</h2>
        <i class="ri-close-large-line" @click="handleClose"></i>
      </div>
      <!-- <SecurityCode code="teamCode" @returnresult="handleReturnResult" /> -->
      <el-form ref="teamCodeForm" :model="form" :rules="rules">
        <el-form-item prop="teamCode">
          <el-input v-model="form.teamCode" autocomplete="off" placeholder="请输入团队邀请码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="activateTeamCode">激活</el-button>
      </div>
      <img class="background" src="@/assets/img/logo.svg" alt="" />
    </div>
  </el-dialog>
</template>

<script>
// import SecurityCode from "./SecurityCode.vue";
import { mapState } from "vuex";
import store from "@/store";
export default {
  name: "SecurityCodeDialog",
  // components: {
  //   SecurityCode,
  // },
  data() {
    return {
      dialogVisible: false,
      form: {
        teamCode: "",
      },
      rules: {
        teamCode: [
          { required: true, message: "请输入团队邀请码", trigger: "blur" },
          { pattern: /^[a-zA-Z0-9]{9,}$/, message: "请输入正确的团队邀请码", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo || {},
    }),
    phonenumber() {
      return this.userInfo.phonenumber;
    },
  },
  methods: {
    handleReturnResult(result) {
      this.teamCode = result;
    },
    openDialog() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.form.teamCode = "";
      this.dialogVisible = false;
    },
    getUserInfo() {
      this.commonApiService.getUserInfo().then((res) => {
        if (res.code == 200) {
          store.commit("setUserInfo", res.data);
        }
      });
    },
    async activateTeamCode() {
      this.$refs.teamCodeForm.validate((valid) => {
        if (!valid) {
          return this.$message.error("请输入正确的团队码");
        } else {
          this.commonApiService.joinTeamByCode({ inviteCode: this.form.teamCode }).then((res) => {
            if (res.code == 200) {
              this.$message.success("激活成功");
              this.dialogVisible = false;
              this.getUserInfo();
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    min-width: 300px !important;
    border-radius: 8px !important;
    background-image: linear-gradient(180deg, #dae6fe 0%, #fff 100%) !important;
  }

  .el-dialog__header {
    padding: 0 !important;
  }

  .el-dialog__body {
    padding: 0 !important;
  }

  .el-dialog__footer {
    text-align: center !important;
  }
}
.dialog-modal-box {
  padding: 24px;
  z-index: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  position: relative;

  .title {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    height: 10%;
  }

  .vip-tool-box-header {
    h2 {
      font-size: 16px;
      font-weight: 600;
      color: #303133;
      margin-bottom: 16px;
    }
  }
  .background {
    z-index: -1;
    position: absolute;
    width: 150px;
    height: 150px;
    top: 10px;
    right: 10px;
    filter: blur(3px);
  }

  .dialog-footer {
    text-align: center;
  }
}
</style>
