<template>
  <el-dialog
    :title="title"
    :visible.sync="showInvitationCode"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    width="30%"
    center
  >
    <div v-show="wxUse == 1" class="sq-ing">
      <el-image :src="src">
        <div slot="placeholder" class="image-slot">加载中<span class="dot">...</span></div>
      </el-image>
      <div class="no-code">
        正在申请中，请耐心等待审批
        <br />
        如有疑问，请联系客服{{ phone }}
      </div>
    </div>
    <div v-show="wxUse == 0">
      <div v-show="type == 1">
        <el-row :gutter="20" @keyup.delete.native="deleteInput">
          <el-col :span="4"><el-input id="autoFocus1" v-model="invitaCod[0]" :maxlength="1"></el-input></el-col>
          <el-col :span="4"><el-input id="autoFocus2" v-model="invitaCod[1]" :maxlength="1"></el-input></el-col>
          <el-col :span="4"><el-input id="autoFocus3" v-model="invitaCod[2]" :maxlength="1"></el-input></el-col>
          <el-col :span="4"><el-input id="autoFocus4" v-model="invitaCod[3]" :maxlength="1"></el-input></el-col>
          <el-col :span="4"><el-input id="autoFocus5" v-model="invitaCod[4]" :maxlength="1"></el-input></el-col>
          <el-col :span="4"><el-input id="autoFocus6" v-model="invitaCod[5]" :maxlength="1"></el-input></el-col>
        </el-row>
        <div class="no-code">
          <el-link type="primary" @click="change(2)">没有邀请码，返回填写申请</el-link>
        </div>
      </div>
      <div v-show="type == 2">
        <div>
          <el-input v-model="reason" type="textarea" :rows="6" placeholder="请输入申请体验理由"> </el-input>
        </div>
        <div class="no-code">
          <el-link type="primary" @click="change(1)">已有邀请码，请按这里</el-link>
        </div>
      </div>
      <div class="no-code">
        <el-button id="submit-code" type="primary" @click="submitTo">提交</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import config from "@/config";

export default {
  name: "InvitationCode",
  props: ["row", "value"],
  data() {
    return {
      invitaCod: [],
      showInvitationCode: false,
      type: 1,
      src: require("@/assets/img/approve.png"),
      phone: config.phone,
      reason: "",
      title: "",
      title1: "输入邀请码",
      title2: "体验申请",
    };
  },
  computed: {
    wxUse() {
      return this.$store.getters.wxUse;
    },
  },
  watch: {
    invitaCod: {
      handler(newValue, oldValue) {
        let lh = newValue.length;
        let id;
        if (lh >= 0 && lh < 6) {
          id = `autoFocus${lh + 1}`;
        } else if (lh == 6) {
          id = `autoFocus${lh}`;
        }
        document.getElementById(id).focus();
      },
    },
    value: {
      handler(val) {
        this.showInvitationCode = val;
        if (val) {
          this.showInvitationCode = val; //父组件传来的值
        }
      },
      immediate: true,
    },
    showInvitationCode(val) {
      this.$emit("input", val); //关键input
    },
  },

  mounted() {
    this.title = this.wxUse == 1 ? "" : this.title1;
    setTimeout(() => {
      document.getElementById("autoFocus1").focus();
    }, 500);
  },
  methods: {
    submitTo() {
      let params = {};
      if (this.type == 1) {
        if (this.invitaCod.length < 6) {
          this.showErrorMsg("请输入邀请码");
          return;
        }
        params.inviteCode = this.invitaCod.join("");
      } else {
        if (this.reason.length <= 0) {
          this.showErrorMsg("申请体验理由不能为空");
          return;
        }
        params.content = this.reason;
      }
      this.commonApiService.userApply(params).then((res) => {
        try {
          if (res.code == 200) {
            window.location.reload(true);
          }
        } catch (e) {
          return;
        }
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    change(type) {
      this.type = type;
      this.title = type == 1 ? this.title1 : this.title2;
    },
    deleteInput() {
      let id = `autoFocus${this.invitaCod.length - 1}`;
      if (this.invitaCod.length >= 1 && this.invitaCod.length <= 6) {
        this.invitaCod.splice(this.invitaCod.length - 1, 1);
      }
    },
  },
};
</script>
<style scoped="stylesheet/scss" lang="scss">
::v-deep {
  .el-input__inner {
    padding: 0 0;
    text-align: center;
  }

  .el-image {
    width: 160px;
  }
}

.no-code {
  text-align: center;
  margin-top: 40px;
}

#submit-code {
  width: 100%;
  color: aliceblue;
}

.sq-ing {
  text-align: center;
}
</style>
