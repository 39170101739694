<template>
  <div class="team-dropdown-list">
    <!-- 个人空间列表 -->
    <div class="space-list">
      <header class="space-list-header">个人身份</header>
      <div class="space-list-content">
        <div class="space-list-item">
          <div class="space-list-left">
            <i class="ri-user-line space-list-item-icon"></i>
            <el-tooltip class="item" effect="dark" :content="userInfo.nickName" placement="top">
              <span class="text-overflow">{{ userInfo.nickName }}</span>
            </el-tooltip>
            <VipLevelIcon style="margin-left: 3px" :vip-level="vipLevel" :vip-member-name="vipMemberName" />
          </div>
          <div class="space-list-right">
            <el-tag v-if="teamId" effect="dark" size="small" @click="switchUserSpace('')">
              <span> 切换</span>
            </el-tag>
            <el-tag v-else size="small">
              <span> 当前身份 </span>
            </el-tag>
          </div>
        </div>
      </div>
    </div>
    <!-- 分割线 -->
    <!-- <div class="space-list-divider"></div> -->
    <!-- 团队列表 -->
    <div class="space-list" style="margin-top: 20px">
      <header class="space-list-header">团队身份</header>
      <div class="space-list-content">
        <div v-for="item in teamLevelVOs" :key="item.id" class="space-list-item">
          <div class="space-list-left">
            <i class="ri-team-line space-list-item-icon"></i>
            <el-tooltip class="item" effect="dark" :content="item.teamName" placement="top">
              <span class="text-overflow">{{ item.teamName }}</span>
            </el-tooltip>
            <VipLevelIcon
              style="margin-left: 3px"
              :vip-level="item.teamVipLevel"
              :vip-member-name="item.teamVipMemberName"
            />
          </div>
          <div class="space-list-right">
            <el-tag v-if="teamId !== item.teamId" effect="dark" size="small" @click="switchUserSpace(item)">
              <span> 切换</span>
            </el-tag>
            <el-tag v-else size="small">
              <span> 当前身份 </span>
            </el-tag>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import currentMemberMixin from "@/mixins/currentMemberMixin";
import VipLevelIcon from "@/components/VipLevelIcon.vue";
import { mapState } from "vuex";
export default {
  name: "TeamDropdownList",
  components: {
    VipLevelIcon,
  },
  mixins: [currentMemberMixin],
  data() {
    return {
      nowTeamId: null,
      userSpace: [
        {
          icon: "ri-user-line",
          id: 1,
          name: "个人空间",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo || {},
    }),
    teamId() {
      return this.userInfo.teamId;
    },
    userLevelVO() {
      return this.userInfo.userLevelVO || {};
    },
    vipLevel() {
      return this.userLevelVO.isExpired ? "0" : this.userLevelVO?.level;
    },
    teamLevelVOs() {
      if (!this.userInfo.teamLevelVOs) return [];
      return this.userInfo.teamLevelVOs.map((item) => ({
        ...item,
        teamVipLevel: item.isExpired ? "0" : item.level,
        teamVipMemberName: item.isExpired ? "普通会员" : item.memberName,
      }));
    },
    vipMemberName() {
      return this.vipLevel > 0 ? this.userInfo.userLevelVO?.memberName : "普通会员";
    },
  },
  created() {
    // this.fetchTeams();
  },
  methods: {
    async switchUserSpace(team = "") {
      const { teamId = "", teamVipLevel, teamName } = team;
      let toast = "";
      if (team && teamVipLevel === "0" && this.vipLevel !== "0") {
        toast = `您当前身份为高级会员, 切换的团队身份【${teamName}】是普通会员, 切换后您将失去高级会员权益, 是否继续?`;
      } else if (!team && teamVipLevel !== "0" && this.vipLevel === "0") {
        toast = `您当前身份为团队【${this.currentMemberInfo.teamName}】高级会员, 切换的个人身份是普通会员, 切换后您将失去高级会员权益, 是否继续?`;
      } else {
        toast = `你正在切换身份，是否要切换【${team ? teamName : "个人"}】身份？`;
      }

      this.$confirm(toast, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.commonApiService.changeTeam(teamId);
          if (res.code == 200) {
            this.$store.dispatch("refreshUserInfo");
            // 切换成功后需要转跳到首页
            this.$router.push({ path: "/" });
            this.$message({
              type: "success",
              message: "切换成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消切换",
          });
        });
    },
  },
};
</script>

<style scoped>
.text-overflow {
  margin: 0 5px;
  max-width: 120px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.team-dropdown-list {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.space-list {
  /* margin-bottom: 10px; */
  .space-list-header {
    font-family: PingFang SC-Regular, PingFang SC;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #909499;
    margin-bottom: 10px;
  }
  .space-list-content {
    .space-list-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .space-list-left {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .space-list-item:hover {
      cursor: pointer;
      background-color: #fff;
      border-radius: 8px;
      padding: 6px;
      transition: all 0.3s;
      color: #1890ff;
      position: relative;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
    .space-list-item-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background: #eff7ff;
      border-radius: 8px;
      margin-right: 3px;
      i {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}
.space-list-divider {
  height: 1px;
  background-color: #e8e8e8;
  margin: 10px 0;
}
</style>
