<template>
  <div v-loading="loading" class="popup-center">
    <div class="top">每日签到</div>
    <!-- <div class="head">
            <div>连续签到领好礼</div>
        </div> -->
    <div class="list">
      <div
        v-for="day in days"
        :key="day.day"
        class="item"
        :class="{ 'item-last': day.day === 7 }"
        :style="{ background: day.background, backgroundSize: 'contain', color: day.color }"
      >
        <div>第{{ day.day }}天</div>
        <div>{{ day.points }}积分</div>
      </div>
    </div>
    <div class="foot">
      <div class="foot-text">已连续签到{{ signInData.continueTimes || 0 }}天</div>
      <el-button type="primary" round :size="$defaultSize" :disabled="isSignedToday()" @click="handleSignIn">{{
        isSignedToday() ? "已签到" : "立即签到"
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";
export default {
  name: "SignIn",
  data() {
    return {
      noSignInPng: require("@/assets/img/no-sign-in.png"),
      signInPng: require("@/assets/img/sign-in.png"),
      noSignInAllPng: require("@/assets/img/no-sign-in-all.png"),
      signInAllPng: require("@/assets/img/sign-in-all.png"),
      signInData: {},
      continueSign: "",
      singleSign: "",
      loading: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo || {},
    }),
    days() {
      return Array.from({ length: 7 }, (_, i) => {
        const day = i + 1;
        const isSignedIn = Number(this.signInData.continueTimes) >= day;
        const isLastDay = day === 7;

        return {
          day,
          points: isLastDay ? this.continueSign : this.singleSign,
          background: isLastDay
            ? `url(${isSignedIn ? this.signInAllPng : this.noSignInAllPng}) no-repeat center`
            : `url(${isSignedIn ? this.signInPng : this.noSignInPng}) no-repeat center`,
          color: isSignedIn ? "#fff" : "#051e56",
        };
      });
    },
  },
  created() {
    this.getUserSignInOneData();
  },
  methods: {
    async handleSignIn() {
      try {
        this.loading = true;
        const res = await this.commonApiService.sign();
        this.$message({
          message: res.code === 200 ? "签到成功" : res.msg,
          type: res.code === 200 ? "success" : "error",
        });
        // 签到成功后重新获取签到数据
        if (res.code === 200) {
          this.getUserSignInOneData();
          this.commonApiService.getUserInfo().then((res) => {
            if (res.code == 200) {
              store.commit("setUserInfo", res.data);
            }
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    isSignedToday() {
      if (!this.signInData.signTime) return false;
      const today = new Date();
      const formattedToday = today.toISOString().split("T")[0];

      const signDate = new Date(this.signInData.signTime);
      const formattedSignDate = signDate.toISOString().split("T")[0];

      return formattedToday === formattedSignDate;
    },
    async getUserSignInOneData() {
      try {
        this.loading = true;
        await this.getSignInDictType();
        const { userId } = this.userInfo;
        const res = await this.commonApiService.getSignList({ pageNum: 1, pageSize: 10, userId });
        const { rows } = res;
        if (rows.length) {
          this.signInData = rows[0];
          const isYesterday = this.isDateRelativeToday(this.signInData.createTime, -1);
          const isToday = this.isDateRelativeToday(this.signInData.createTime, 0);

          if (!isYesterday && !isToday) {
            console.log("重置前的连续签到天数:", this.signInData.continueTimes);
            this.signInData.continueTimes = 0;
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getSignInDictType() {
      const tempName = "sys_user_sign_rule";
      const res = await this.commonApiService.getDictData(tempName);
      if (res && res.data) {
        this.continueSign = this.getDictValueByLabel(res.data, "continue_sign");
        this.continueSign = Number(this.continueSign) + 1;
        this.singleSign = this.getDictValueByLabel(res.data, "single_sign");
      }
    },
    getDictValueByLabel(data, label) {
      return data.find((item) => item.dictLabel === label)?.dictValue;
    },
    isDateRelativeToday(dateStr, offset) {
      const targetDate = new Date();
      targetDate.setDate(targetDate.getDate() + offset);
      const date = new Date(dateStr.replace(/-/g, "/")); // 为了兼容性，将'-'替换为'/'

      return (
        date.getFullYear() === targetDate.getFullYear() &&
        date.getMonth() === targetDate.getMonth() &&
        date.getDate() === targetDate.getDate()
      );
    },
  },
};
</script>

<style scoped lang="scss">
.popup-center {
  margin-top: 8px;
  height: 100%;
  // padding: 8px;
  background: linear-gradient(180deg, #dae6fe 0%, #fdfdff 100%);
  border-radius: 8px;

  .top {
    padding: 8px;
    height: 10%;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    color: #051e56;
    justify-content: center;
    align-items: center;
  }

  .head {
    font-weight: bold;
    font-size: 16px;
    color: #051e56;
    margin-bottom: 10px;
    padding-left: 5px;
  }

  .list {
    height: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 80px;
      font-size: 12px;
      color: #051e56;
      line-height: 45px;
      cursor: pointer;
    }

    .item-last {
      align-items: flex-start;
      width: 42%;
      height: 80px;
      font-size: 14px;
      color: #051e56;
      line-height: 45px;
      text-align: left;
      padding-left: 20px;
    }
  }

  .foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 7.5px;

    .foot-text {
      font-weight: bold;
      font-size: 14px;
      color: #051e56;
    }

    .bottom-item-btn {
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #6289f8;
      border-radius: 20px;
      color: #ffffff;
    }

    .disabled {
      background-color: #ccc;
      color: #666;
      pointer-events: none;
    }
  }
}
</style>
