import showLoading from "./modules/showLoading";
import user from "./modules/user";
import getters from "./getters";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    showLoading,
    user,
  },
  getters,
});

export default store;
