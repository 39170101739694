<template>
  <div class="customer-service-wechat-qr">
    <iframe
      src="https://ops.lzwcai.com/chatbot/hLokUY2K06znFqBY"
      style="width: 100%; height: 100%; min-height: 500px"
      frameborder="0"
      allow="microphone"
    >
    </iframe>
    <!-- <h3 class="customer-service-wechat-qr-title">智能客服</h3>
    <div class="customer-service-wechat-qr-inner">
      打开微信扫一扫
      <br />
      关注公众号直接咨询
    </div>
    <img
      class="customer-service-wechat-qr-img"
      style="width: 188px; height: 188px"
      src="@/assets/img/service.jpg"
      fit="cover"
    /> -->
  </div>
</template>

<script>
export default {
  name: "CustomerServiceWechatQR",
};
</script>

<style scoped>
.customer-service-wechat-qr {
  width: 100%;
  height: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px !important;
  background-image: linear-gradient(180deg, #dae6fe 0%, #fff 100%) !important;
  position: relative;
}

.customer-service-wechat-qr-img {
  width: 188px;
  height: 188px;
  text-align: center;
}

.customer-service-wechat-qr-title {
  margin: 10px;
  text-align: center;
  font-size: 16px;
}

.customer-service-wechat-qr-inner {
  text-align: center;
  font-size: 14px;
}
</style>
