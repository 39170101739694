import Router from "./index";
import { getCookie } from "@/utils/util";
import commonApiService from "@/api/commonApiService";
import store from "@/store";

Router.beforeEach(async (to, from, next) => {
  let token = getCookie("token");
  const requireAuth = to.meta?.requireAuth;
  // 如果路由需要认证
  if (requireAuth) {
    // 如果 token 存在，允许导航
    if (token) {
      // 获取用户信息
      const { code, data } = await commonApiService.getUserInfo();
      if (code !== 200) {
        next({
          path: "/login",
          query: { redirect: to.fullPath },
        });
      }
      store.commit("setUserInfo", data);
      next();
    } else {
      // 如果 token 不存在，重定向到登录页面
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    // 如果路由不需要认证，直接允许导航
    next();
  }
});
