import Vue from "vue";
import ElementUI from "element-ui";
import App from "./App";
import router from "./router";
import store from "@/store";
import axios from "axios";
import VideoPlayer from "vue-video-player";
import commonApiService from "./api/commonApiService";
import commonMethods from "./utils/commonMethods";
import * as custom from "./utils/util";
import VueCropper from "vue-cropper";

// Styles
import "./assets/icon/iconfont.css";
import "./assets/style/main.scss";
import "remixicon/fonts/remixicon.css";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";

// ECharts
import * as echarts from "echarts";

// Plugins -> Global route guard
import "./router/beforeEachRouter";

// Vue configuration
Vue.config.productionTip = process.env.NODE_ENV === "production";

// Register global properties
Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios;
Vue.prototype.commonApiService = commonApiService;
Vue.prototype.$defaultSize = "small";

Vue.prototype.$bus = new Vue();

// Use third-party plugins
Vue.use(ElementUI);
Vue.use(VideoPlayer);
Vue.use(commonMethods);
Vue.use(VueCropper);

// Register global filters
Object.keys(custom).forEach((key) => {
  Vue.filter(key, custom[key]);
});

// Create new Vue instance
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
