<template>
  <div>
    <el-dialog
      v-loading="loading"
      append-to-body
      :visible="showPointView"
      width="40%"
      center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="point-shop-center">
        <div class="head-user-msg">
          <div class="left">
            <div class="user-avatar">
              <img slot="reference" class="el-icon-user-solid avatar" :src="avatarImageUrl" />
            </div>
            <div class="user-name">
              <div class="user-name-text">
                {{ userInfo.nickName }}
                <span class="vip-level-name" :style="getVipLevelStyle">{{ vipMemberName || "" }}</span>
              </div>
              <div class="vip-info">
                <span class="vip-level-name" style="margin-right: 10px">当前积分：{{ userInfo.point || 0 }}</span>
                <el-popover
                  placement="right"
                  :title="comparisonRules.title"
                  width="200"
                  trigger="hover"
                  :content="comparisonRules.content"
                >
                  <i slot="reference" class="el-icon-question"></i>
                </el-popover>
              </div>
            </div>
          </div>
          <div class="right">
            <i class="ri-close-large-line" style="cursor: pointer" @click="handleClose"></i>
          </div>
        </div>
        <div class="recharge-list">
          <div v-for="(item, index) in rechargeList" :key="index" class="recharge-item">
            <el-image :src="item.productUrl" class="recharge-image" fit="fill">
              <div slot="error" class="image-slot">
                <i class="ri-image-line"></i>
                <p style="font-size: 12px; color: #606266; margin-top: 2px">商品效果图加载失败</p>
              </div>
            </el-image>
            <div class="recharge-msg-box">
              <div class="recharge-title">
                <span>{{ item.productName }}</span>
                <div @click="purchase(item)">兑换</div>
              </div>
              <div class="recharge-price">
                <span>兑换价：</span>
                <span style="color: black; font-weight: 700">{{ item.price }}积分</span>
              </div>
              <!-- <div class="recharge-description">
                <span >{{ item.description }}</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import avatarImage from "@/assets/img/avatar.png";
import { mapState } from "vuex";
import store from "@/store";
import { vipLevelStyle } from "@/config";
import { COMPARISON_RULES } from "@/config";
export default {
  name: "Point",
  props: {
    showPointView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      comparisonRules: COMPARISON_RULES,
      showVipView: false,
      rechargeList: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo || {},
    }),
    avatarImageUrl() {
      return this.userInfo.avatar || avatarImage;
    },
    getVipLevelStyle() {
      return vipLevelStyle[this.vipLevel];
    },
    vipLevel() {
      return this.userInfo.userLevelVO.isExpired ? 0 : this.userInfo.userLevelVO?.level;
    },
    vipMemberName() {
      return this.vipLevel > 0 ? this.userInfo.userLevelVO?.memberName : "普通会员";
    },
  },
  mounted() {
    this.getRecharge();
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    async getRecharge() {
      const params = {
        pageNum: 1,
        pageSize: 100,
      };
      const res = await this.commonApiService.getProductList(params);
      if (res.code === 200) {
        this.rechargeList = res.rows;
        console.log(this.rechargeList);
      }
    },
    async getVipInfo() {
      const res = await this.commonApiService.getUserInfo();
      if (res.code == 200) {
        store.commit("setUserInfo", res.data);
      }
    },
    async purchase(item) {
      this.$msgbox({
        title: "积分兑换",
        message: this.$createElement("p", null, [
          this.$createElement("span", null, `确定使用`),
          this.$createElement("span", { style: "font-weight: bold;" }, `${item.price}积分`),
          this.$createElement("span", null, `兑换`),
          this.$createElement(
            "span",
            { style: "margin-left: 5px;margin-right: 5px; font-weight: bold; color: #409EFF;" },
            `${item.productName}`
          ),
          this.$createElement("span", null, `商品吗？`),
          this.$createElement(
            "p",
            { style: "font-size: 12px; color: #606266; margin-top: 2px" },
            `(注意：兑换比对次数仅当月有限，兑换后不可退还，请谨慎操作！)`
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            // 假设这里调用purchaseProduct方法来处理购买逻辑
            this.purchaseProduct(item)
              .then(() => {
                done();
                instance.confirmButtonLoading = false;
              })
              .catch(() => {
                done();
                instance.confirmButtonLoading = false;
              });
          } else {
            done();
          }
        },
      })
        .then((action) => {
          if (action === "confirm") {
            // this.$message({
            //   type: "success",
            //   message: "兑换成功",
            // });
          }
        })
        .catch(() => {
          // 处理取消操作或关闭MessageBox的逻辑
        });
    },
    async purchaseProduct(item) {
      this.loading = true;
      try {
        const params = {
          productId: item.id,
          userId: this.userInfo.id,
        };
        const res = await this.commonApiService.purchaseProduct(params);
        if (res.code === 200) {
          this.$message.success("兑换成功");
          // 刷新用户信息
          await this.getVipInfo();
        } else {
          // 如果响应码不是200，也给出提示
          // this.$message.error(res.message || "兑换失败，请稍后再试");
        }
      } catch (error) {
        // 捕获到错误时的处理
        console.error("购买过程中出现错误：", error);
        this.$message.error("兑换过程中出现错误，请联系客服");
      } finally {
        this.loading = false; // 确保在请求结束后，无论成功还是失败，都会停止加载状态
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  // .el-checkbox__inner {
  //   border-radius: 50%;
  //   background: black !important;
  //   border: 1px solid black !important;
  // }

  .el-dialog--center .el-dialog__body {
    padding: 0 !important;
  }

  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__footer {
    padding: 0;
  }

  .el-dialog__wrapper {
    border-radius: 8px;
  }

  .el-dialog {
    min-width: 640px !important;
    border-radius: 8px;
  }

  .el-dialog__headerbtn {
    color: #909399;
  }

  .el-dialog__title {
    font-size: 16px;
    font-weight: 600;
    color: #303133;
  }

  .el-dialog__body {
    padding: 0;
  }
}

.head-user-msg {
  background-color: #ebeef5;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
  border-radius: 8px 8px 0 0;

  .left {
    display: flex;

    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      line-height: 32px;
      text-align: center;
      font-size: 18px;
      border-radius: 50%;
      color: #fff;
      background-size: cover;
      background-position: center;
      border: 2px solid #e5e7e9;
      margin-right: 5px;
    }

    .user-name {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      padding-left: 5px;
      max-width: 105px;
      min-width: 80px;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-top: 4px;

      .user-name-text {
        display: flex;
        align-items: center;
        font-weight: 700;
        margin-left: 5px;
        font-size: 16px;

        @keyframes shine {
          0% {
            background-position: -200px;
          }

          40%,
          100% {
            background-position: 200px;
          }
        }

        .vip-level-name {
          font-weight: 600;
          font-size: 12px !important;
          padding: 1px 4px;
          border-radius: 4px;
          margin-left: 6px;

          background: linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.1) 25%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0.1) 75%
          );
          background-size: 200px 100%;
          animation: shine 3s linear infinite;
        }
      }

      .vip-icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }

      .vip-level-name {
        font-size: 12px;
        margin-left: 5px;
      }

      .user-avatar {
        display: flex;
        align-items: center;
      }

      .vip-info {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 999;
    font-weight: 800;
    font-size: 18px;
  }

  .vip-icon-bg {
    position: absolute;
    width: 250px;
    height: 250px;
    right: -40px;
    bottom: -74px;
    filter: blur(8px);
  }
}
.point-shop-center {
  // padding: 20px;

  .recharge-list {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 20px;

    .recharge-item {
      overflow: hidden;
      width: 180px;
      height: 300px;
      background-color: #ffffff;
      margin-right: 20px;
      margin-bottom: 20px;
      border-radius: 10px;
      cursor: pointer;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      transition: transform 0.44s ease;
      will-change: transform;
      .recharge-item:hover {
        transform: translateY(-10px);
      }

      .recharge-image {
        background-color: #ebeef5;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 75%;
      }

      .recharge-msg-box {
        padding: 10px;

        .recharge-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          color: #333;
          margin-bottom: 10px;

          span {
            font-size: 14px;
            font-weight: 700;
          }

          div {
            background: #409eff;
            padding: 2px 6px;
            color: #fff;
            cursor: pointer;
            border-radius: 8px;
          }
        }

        .recharge-price {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
