<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { setCookie } from "./utils/util";
export default {
  name: "App",
  mounted() {
    //el-upload火狐浏览器文件拖入取消打开新窗口
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 4px !important;
  height: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.no-select-img {
  -webkit-user-select: none; /* 禁止选择文本 */
  user-select: none;
  pointer-events: none; /* 禁止鼠标事件 */
}

.animate__bounceIn {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

/* Bouncing entrances  */
@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: #090a0a;
  width: 100%;
  height: 100%;
  user-select: none;
}

.customIntroTooltip .introjs-tooltip-title {
  color: #2e3f69;
}

path {
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  animation: act 8s linear infinite;
}

@keyframes act {
  from {
    stroke-dasharray: 5, 2.5;
  }

  to {
    stroke-dasharray: 6, 2.5;
  }
}

body .introjs-helperLayer {
  border-radius: 8px;
  box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 30px 1px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}

body .introjs-helperNumberLayer {
  display: none;
}

body .introjs-skipbutton {
  background: none !important;
  color: #333 !important;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

body .introjs-nextbutton,
body .introjs-donebutton {
  border: none !important;
  background-color: #6289f8 !important;
  color: #ffffff !important;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    color: #ffffff;
    background-color: #6289f8;
    /* 悬停时更深的蓝色 */
  }
}

body .introjs-disabled {
  display: none;
}

body .introjs-button {
  padding: 5px 10px !important;
  text-shadow: none;
  border-radius: 4px;
  border: none !important;
  outline: none;
  box-shadow: none;
  text-shadow: none;
}

body .introjs-arrow {
  border: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

body .introjs-arrow.left,
body .introjs-arrow.left-bottom {
  height: 16px;
  width: 16px;
  left: -20px;
}

body .introjs-arrow.top,
body .introjs-arrow.top-middle,
body .introjs-arrow.top-right {
  height: 16px;
  width: 16px;
  top: -20px;
}

body .introjs-arrow.bottom,
body .introjs-arrow.bottom-right,
body .introjs-arrow.bottom-middle {
  height: 16px;
  width: 16px;
  bottom: -20px;
  transform: scaleY(-1);
}

body .introjs-tooltip {
  /* padding: 20px; */
  width: auto;
  max-width: 360px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

body .introjs-tooltiptext .title {
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 12px;
}

body .introjs-tooltiptext .content {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

body .introjs-tooltiptext {
  margin-bottom: 12px;
}

body .introjs-bullets {
  display: none;
}
</style>
