<template>
  <div>
    <el-dialog
      :visible.sync="showVipPayView"
      width="40%"
      center
      :close-on-click-modal="false"
      append-to-body
      :show-close="false"
      @close="handleClose"
    >
      <el-row v-loading="loading">
        <el-col :span="24">
          <div class="head-user-msg" :class="getMemberBgClass()">
            <div class="left">
              <div class="user-avatar">
                <img slot="reference" class="el-icon-user-solid avatar" :src="avatarImageUrl" />
              </div>
              <div class="user-name">
                <div class="user-name-text">
                  {{ userInfo.nickName }}
                  <span class="vip-level-name" :style="getVipLevelStyle">{{ vipMemberName || "" }}</span>
                </div>
                <div class="vip-info">
                  <span v-if="vipLevel <= 0" class="vip-level-name">开通会员享受更多权益</span>
                  <span v-else class="vip-level-name" :class="`vip-font-color-info-${currentVipData.level}`"
                    >{{ vipMemberName }}有效期至 {{ userVipExpireTime }}</span
                  >
                </div>
              </div>
            </div>
            <div class="right">
              <i class="ri-close-large-line" style="cursor: pointer" @click="handleClose"></i>
            </div>
            <img :src="getVipIconForVipLevel()" :style="getUserVipHeadIconStyle()" class="vip-icon-bg" />
          </div>
          <div class="vip-pay-content" :class="`vip-pay-content-bg-${currentVipData.level}`">
            <div class="left" :class="`vip-pay-content-item-bg-${currentVipData.level}`">
              <div class="title" :class="`vip-font-color-${currentVipData.level}`">会员权益</div>
              <p style="margin-bottom: 0px" :class="`vip-font-color-default-${currentVipData.level}`">小程序电脑通用</p>
              <p style="margin: 0px" :class="`vip-font-color-default-${currentVipData.level}`">包含以下会员权益</p>
              <div v-if="currentVipData && currentVipData.functions" class="pay-member-note">
                <div v-for="(item, index) in currentVipData.functions" :key="index" class="pay-member-note-item">
                  <span :class="`vip-font-color-default-${currentVipData.level}`">{{ item.describe }}</span>
                  <i :class="`vip-font-color-${currentVipData.level}`" class="ri-check-line"></i>
                </div>
              </div>
              <div class="pay-member-note">
                <div v-for="(item, index) in getFeatureBenefits()" :key="index" class="pay-member-note-item">
                  <span :class="`vip-font-color-default-${currentVipData.level}`">{{ item.label }}</span>
                  <span :class="`vip-font-color-${currentVipData.level}`">{{ item.describe }}</span>
                </div>
              </div>
              <el-link class="vip-rights-detail" plain size="small" @click="goVipDetail()"
                >查看权益详情<i class="ri-arrow-right-s-line"></i
              ></el-link>
            </div>
            <div class="right">
              <div
                v-if="vipData && vipData.length > 0"
                :class="`vip-pay-amount vip-pay-content-item-bg-${currentVipData.level}`"
              >
                <div
                  v-for="(item, index) in currentVipData.priceList"
                  :key="index"
                  class="amount"
                  :class="[
                    { [`member-bg-price-${currentVipData.level}`]: true },
                    {
                      [`selected-${currentVipData.level}`]: currentPriceIndex === index,
                    },
                    { selected: currentPriceIndex === index },
                  ]"
                  @click="selectPrice(item, index)"
                >
                  <div v-if="currentVipData.isNew" class="product-tag">新用户专享</div>
                  <!-- <div class="vip-name" :class="`vip-font-color-${currentVipData.level}`">{{ currentVipData.memberName
                    }}</div> -->
                  <div class="vip-time" :class="`vip-font-color-${currentVipData.level}`">
                    {{ getVipTime(item) }}个月
                  </div>
                  <div class="vip-price" :class="`vip-font-color-${currentVipData.level}`">
                    <span class="syc">¥</span>
                    {{ getVipPrice(item) }}
                  </div>
                  <del class="del-price" :class="`vip-font-color-info-${currentVipData.level}`">{{
                    getVipPriceWithFallback(item)
                  }}</del>
                  <div class="vip-icon-box">
                    <img v-show="currentPriceIndex === index" :src="getVipIcon()" class="vip-icon" />
                  </div>
                  <div class="foot-box">
                    <div :class="[`vip-font-color-${currentVipData.level}`, `foot-${currentVipData.level}`, 'foot']">
                      每天低至 {{ getVipPriceOnDay(item) }} 元
                    </div>
                  </div>
                </div>
              </div>
              <div class="vip-pay-qr-code" :class="`vip-pay-content-item-bg-${currentVipData.level}`">
                <div v-if="isCurrentVipLower" class="vip-pay-qr-code-img">
                  <div class="ag-mask">
                    <p>您已是尊贵的</p>
                    <p>{{ vipMemberName }}</p>
                  </div>
                </div>
                <div v-else class="vip-pay-qr-code-img">
                  <div v-if="qrCodeExpired" class="ag-mask">
                    <p>二维码已过期</p>
                    <p style="padding-bottom: 20px">请刷新二维码</p>
                    <div class="ag-btn" @click="activateNowVip(currentVipData)">刷新</div>
                  </div>
                  <div v-if="!isAgreed" class="ag-mask">
                    <p>请先阅读并同意</p>
                    <p style="padding-bottom: 20px">相关协议再扫码支付</p>
                    <div class="ag-btn" @click="isAgreed = true">确认同意</div>
                  </div>
                  <div v-else class="qr-code">
                    <el-image style="width: 120px; height: 120px" :src="QRCodeSrc" class="qr-code" />
                    <div class="pay-tip"><img src="@/assets/img/wepay.svg" />微信扫码支付</div>
                  </div>
                </div>
                <div class="vip-pay-qr-code-price">
                  <div class="pay-price-info" :class="`vip-font-color-info-${currentVipData.level}`">
                    同意并支付
                    <span
                      v-if="currentVipData && currentVipData.priceList"
                      class="pay-price"
                      :class="`vip-font-color-price-${currentVipData.level}`"
                    >
                      <span>¥</span>
                      {{ getVipPrice(currentPriceData) }}
                    </span>
                    <del>¥{{ getVipPriceWithFallback(currentPriceData) }}</del>
                  </div>
                  <div style="margin-top: 10px" class="pay-btn" :class="`vip-font-color-info-${currentVipData.level}`">
                    <el-checkbox v-model="isAgreed" style="margin-right: 5px"></el-checkbox>
                    <span>请先阅读并同意</span>
                    <el-link type="primary" @click="toServiceAgreement">《服务协议》</el-link>
                    <el-link type="primary" @click="toPrivacyPolicy">《隐私协议》</el-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import avatarImage from "@/assets/img/avatar.png";
import { mapState } from "vuex";
import { toYearMonthDay } from "@/utils/util";
import store from "@/store";
import { vipLevelIcon, DEFAULT_VIP_LEVEL } from "@/config";
import { Socket } from "@/utils/socketClientApi";
import { vipLevelStyle, SERVICE_AGREEMENT_URL, PRIVACY_POLICY_URL, FEATURE_BENEFITS_LEVELS } from "@/config";
import Vue from "vue";
export default {
  name: "VipPay",
  props: ["value"],
  data() {
    return {
      isAgreed: false,
      currentPriceData: {},
      currentPriceIndex: 0,
      currentVipData: {},
      currentVipIndex: 0,
      showVipPayView: true,
      selevtPrice: 0,
      QRCodeSrc: "",
      vipData: "",
      socket: "",
      qrCodeExpired: false,
      expirationTimer: null,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo || {},
    }),
    vipLevel() {
      // return 2;
      return this.userInfo.userLevelVO.isExpired ? 0 : this.userInfo.userLevelVO?.level;
    },
    isCurrentVipLower() {
      return Number(this.currentVipData.level) < Number(this.vipLevel);
    },
    avatarImageUrl() {
      return this.userInfo.avatar || avatarImage;
    },
    vipMemberName() {
      return this.vipLevel > 0 ? this.userInfo.userLevelVO?.memberName : "普通会员";
    },
    getVipLevelStyle() {
      return vipLevelStyle[this.vipLevel];
    },
    userVipExpireTime() {
      return toYearMonthDay(this.userInfo.userLevelVO?.expireTime);
    },
    isMaxVipLevel() {
      if (!this.vipData || this.vipData.length === 0) {
        return false; // 如果 vipData 为空或不存在，返回 false
      }
      const maxLevel = Math.max(...this.vipData.map((item) => item.level));
      return Number(maxLevel) === Number(this.vipLevel);
    },
  },
  watch: {
    isAgreed(newValue) {
      if (newValue === true) {
        this.activateNowVip(this.currentVipData);
      }
    },
  },
  async mounted() {
    setTimeout(() => {}, 500);
    this.getMemberProduct();
    this.registerSocketListener();
  },
  beforeDestroy() {
    this.unregisterSocketListener();
  },
  methods: {
    getFeatureBenefits() {
      const benefits = FEATURE_BENEFITS_LEVELS.find((item) => item.level === Number(this.currentVipData.level));
      return benefits?.benefits || [];
    },
    // 跳转隐私协议
    toServiceAgreement() {
      window.open(SERVICE_AGREEMENT_URL, "_blank");
    },
    // 跳转隐私协议
    toPrivacyPolicy() {
      window.open(PRIVACY_POLICY_URL, "_blank");
    },
    goVipDetail() {
      this.$router.push({
        path: `/member/memberBenefits`,
      });
    },
    getVipIconForVipLevel() {
      if (this.vipLevel === DEFAULT_VIP_LEVEL.SUPER_VIP) {
        return vipLevelIcon[this.vipLevel];
      }
      if (this.vipLevel === DEFAULT_VIP_LEVEL.HIGH_VIP && this.currentVipData.level === DEFAULT_VIP_LEVEL.HIGH_VIP) {
        return vipLevelIcon[this.vipLevel];
      }
      return vipLevelIcon[this.currentVipData.level];
    },
    getMemberBgClass() {
      if (this.vipLevel === DEFAULT_VIP_LEVEL.SUPER_VIP) {
        return `member-bg-open-vip-${this.vipLevel}`;
      }
      if (this.vipLevel === DEFAULT_VIP_LEVEL.HIGH_VIP && this.currentVipData.level === DEFAULT_VIP_LEVEL.HIGH_VIP) {
        return `member-bg-open-vip-${this.vipLevel}`;
      }
      return `member-bg-${this.currentVipData.level}`;
    },
    getUserVipHeadIconStyle() {
      if (this.isMaxVipLevel || this.vipLevel == this.currentVipData.level) {
        return {
          filter: "none !important",
        };
      }
      return {};
    },
    getVipLevelIcon() {
      return vipLevelIcon[this.vipLevel];
    },
    getVipTime(item) {
      return item.expires;
    },
    getVipPriceWithFallback(item) {
      if (item) {
        return item.originalPrice || item.price;
      }
      return "*.**";
    },
    getVipPrice(item) {
      if (item) {
        return this.currentVipData.isNew ? item.firstPrice : item.price;
      }
      return "*.**";
    },
    getVipPriceOnDay(item) {
      // 检查 priceList 是否存在且长度大于0
      if (item) {
        const expires = item.expires;
        const day = expires * 30;
        // 确保 day 不为0来避免除以0的情况
        if (day > 0) {
          return (item.price / day).toFixed(2);
        }
      }
      // 如果 priceList 不存在、为空或者 day 为0，则返回一个默认值或进行错误处理
      return "0.00"; // 或者根据实际情况进行调整
    },
    getVipIcon() {
      return vipLevelIcon[this.currentVipData.level];
    },
    handleClose() {
      this.$emit("close");
    },
    registerSocketListener() {
      Socket.on("vip_pay_event", this.vipPayEvent);
    },
    unregisterSocketListener() {
      Socket.off("vip_pay_event");
    },
    vipPayEvent(res) {
      console.log(res, 2222);
      //0, "创建" 1, "待支付2，"已支付" 3, "取消"4, "退款"
      if (res.code == 2) {
        //支付成功
        this.getVipInfo();
        this.showVipPayView = false;
        this.showSuccessMsg("开通成功");
      } else if (res.code == 3 || res.code == 4) {
        console.log(res.msg);
        this.showErrorMsg("交易失败，原因为：" + res.msg + ",请您重新选择需要开通的会员。");
      }
    },
    priceFun(item) {
      return item.priceList[this.selevtPrice].price;
    },
    getMemberProduct() {
      this.commonApiService.getMemberProduct().then((res) => {
        this.vipData = res.data.filter((item) => !(item.priceList.length === 0));
        // 设置默认选中
        this.currentVipIndex = this.vipData.length - 1;
        this.currentVipData = this.vipData[this.currentVipIndex];
        this.currentPriceIndex = this.currentVipData.priceList.length - 1;
        this.currentPriceData = this.currentVipData.priceList[this.currentPriceIndex];
        // this.$nextTick(() => {
        //   this.activateNowVip(this.currentVipData);
        // });
      });
    },
    async activateNowVip() {
      // if (this.loading) {
      //   return;
      // }
      if (this.currentVipData.level < this.vipLevel) {
        console.log("选中的会员等级低于当前会员等级，不调用接口");
        return;
      }
      clearInterval(this.expirationTimer);
      this.qrCodeExpired = false;
      this.loading = true;
      const { priceList } = this.currentVipData;
      let productId = priceList[this.currentPriceIndex].id;
      let params = {
        from: "pc",
        productId: productId,
      };
      try {
        const res = await this.commonApiService.activateNowVIP(params);
        this.QRCodeSrc = "data:image/jpg;base64," + res.data.qrCode;
      } catch (error) {
        console.error("激活VIP失败:", error);
      } finally {
        this.loading = false;
        this.expirationTimer = setTimeout(() => {
          this.qrCodeExpired = true;
        }, 2000 * 60);
      }
    },
    getVipInfo() {
      this.commonApiService.getUserInfo().then((res) => {
        if (res.code == 200) {
          store.commit("setUserInfo", res.data);
        }
      });
    },
    async selectVip(item, index) {
      if (this.currentVipIndex === index) return;
      await this.activateNowVip(item);
      this.currentVipIndex = index;
      this.currentVipData = item;
    },
    async selectPrice(item, index) {
      this.currentPriceIndex = index;
      this.currentPriceData = item;
      await this.activateNowVip();
    },
    countClass(price) {
      if (price.recommend) {
        return "vip-time-span-tj";
      } else {
        return "vip-time-span";
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-dialog--center .el-dialog__body {
    padding: 0 !important;
  }

  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__footer {
    padding: 0;
  }

  .el-dialog__wrapper {
    border-radius: 8px;
  }

  .el-dialog {
    border-radius: 8px;
    min-width: 750px !important;
  }

  .el-dialog__headerbtn {
    color: #909399;
  }

  .el-dialog__title {
    font-size: 16px;
    font-weight: 600;
    color: #303133;
  }

  .el-dialog__body {
    padding: 0;
  }
}

.vip-font-color-info-0 {
  color: #222 !important;
}

.vip-font-color-info-1 {
  color: #222 !important;
}

.vip-font-color-info-2 {
  color: #c8c8c8 !important;
}

.vip-font-color-price-0 {
  color: #ff5b16 !important;
}

.vip-font-color-price-1 {
  color: #ff5b16 !important;
}

.vip-font-color-price-2 {
  color: #ffe194 !important;
}

.member-bg-price-0 {
  background: #fefcf5 !important;
}

.member-bg-price-1 {
  background: #fefcf5 !important;
}

.member-bg-price-2 {
  background: #464562 !important;
}

.vip-font-color-0 {
  color: #000000 !important;
}

.vip-font-color-1 {
  color: #693f16 !important;
}

.vip-font-color-2 {
  color: #ffe194 !important;
}

.vip-font-color-default-0 {
  color: #000000 !important;
}

.vip-font-color-default-1 {
  color: #000000 !important;
}

.vip-font-color-default-2 {
  color: #ffffff !important;
}

.vip-pay-content-item-bg-0 {
  background: #ffffff !important;
}

.vip-pay-content-item-bg-1 {
  background: #ffffff !important;
}

.vip-pay-content-item-bg-2 {
  background: #3f3f65 !important;
}

.vip-pay-content-bg-0 {
  background: #ffffff !important;
}

.vip-pay-content-bg-2 {
  background: #ffffff !important;
}

.vip-pay-content-bg-2 {
  background: #303149 !important;
}

.selected-0 {
  background: linear-gradient(to bottom, #fff4d8 0%, #ffeab3 100%) !important;
}

.selected-1 {
  background: linear-gradient(to bottom, #fff4d8 0%, #ffeab3 100%) !important;
}

.member-bg-0,
.member-bg-1,
.member-bg-2 {
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  position: relative;
  width: 100%;
  height: 100%;
}

.member-bg-open-vip-0,
.member-bg-open-vip-1,
.member-bg-open-vip-2 {
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  position: relative;
  width: 100%;
  height: 100%;
}

.member-bg-open-vip-0 {
  background: linear-gradient(to bottom, rgba(201, 216, 253, 0.95) 0%, rgba(171, 200, 250, 0.95) 100%) !important;
  height: 100%;
  box-sizing: border-box;
  color: #000000 !important;
}

.member-bg-open-vip-1 {
  background: linear-gradient(to bottom, rgba(254, 248, 233, 0.95) 0%, rgba(251, 231, 191, 0.95) 100%) !important;
  height: 100%;
  box-sizing: border-box;
  color: #000000 !important;
}

.member-bg-open-vip-2 {
  background: linear-gradient(to bottom, rgba(63, 63, 100, 0.95) 0%, rgba(48, 49, 73, 0.95) 100%) !important;
  height: 100%;
  box-sizing: border-box;
  color: #ffffff !important;

  .vip-level-name {
    color: #c8c8c8 !important;
  }
}

.member-bg-0 {
  background: linear-gradient(to bottom, rgba(201, 216, 253, 0.95) 0%, rgba(171, 200, 250, 0.95) 100%) !important;
  height: 100%;
  box-sizing: border-box;
  color: #000000 !important;
}

.member-bg-1 {
  background: linear-gradient(to right, #fbfbfb 0%, #f3f3f3 100%) !important;
  height: 100%;
  box-sizing: border-box;
  color: #000000 !important;
}

.member-bg-2 {
  background: linear-gradient(to right, rgba(24, 22, 34, 1) 0%, rgba(26, 24, 36, 1) 100%) !important;
  height: 100%;
  box-sizing: border-box;
  color: #ffffff !important;
}

.head-user-msg {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;

  .left {
    display: flex;

    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      line-height: 32px;
      text-align: center;
      font-size: 18px;
      border-radius: 50%;
      color: #fff;
      background-size: cover;
      background-position: center;
      border: 2px solid #e5e7e9;
      margin-right: 5px;
    }

    .user-name {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      padding-left: 5px;
      max-width: 105px;
      min-width: 80px;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-top: 4px;

      .user-name-text {
        display: flex;
        align-items: center;
        font-weight: 700;
        margin-left: 5px;
        font-size: 16px;

        @keyframes shine {
          0% {
            background-position: -200px;
          }

          40%,
          100% {
            background-position: 200px;
          }
        }

        .vip-level-name {
          font-weight: 600;
          font-size: 12px !important;
          padding: 1px 4px;
          border-radius: 4px;
          margin-left: 6px;

          background: linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.1) 25%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0.1) 75%
          );
          background-size: 200px 100%;
          animation: shine 3s linear infinite;
        }
      }

      .vip-icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }

      .vip-level-name {
        font-size: 12px;
        margin-left: 5px;
      }

      .user-avatar {
        display: flex;
        align-items: center;
      }

      .vip-info {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 999;
    font-weight: 800;
    font-size: 18px;
  }

  .vip-icon-bg {
    position: absolute;
    width: 250px;
    height: 250px;
    right: -40px;
    bottom: -74px;
    filter: blur(8px);
  }
}

.vip-pay-content {
  border-radius: 0 0 8px 8px;
  display: flex;
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px;
  box-sizing: border-box;

  .left {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 20%;
    padding: 20px;
    background: #fff;
    margin-right: 10px;
    position: relative;

    .vip-rights-detail {
      position: absolute;
      bottom: 20px;
      text-align: center;
      left: 44%;
      transform: translateX(-37%);
      text-align: center;
    }

    .title {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: #303133;
    }

    p {
      text-align: center;
      font-size: 12px;
      color: #606266;
    }

    .pay-member-note {
      margin-top: 10px;
    }

    .pay-member-note-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
    }
  }

  .right {
    width: 80%;

    .vip-pay-amount {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 20px;
      background: #fff;
      display: flex;
      overflow: hidden;

      .selected {
        border: 1px solid #f8a467 !important;
        position: relative;
        // overflow: hidden;
      }

      .amount {
        // overflow: visible !important;
        box-sizing: border-box;
        cursor: pointer;
        padding: 10px 0px;
        position: relative;
        width: 120px;
        height: 140px;
        border-radius: 4px;
        margin-right: 10px;
        border: 1px solid #d7d9de;
        text-align: center;
        color: #444e63;

        .del-price {
          font-size: 12px;
          text-align: center;
          position: relative;
          z-index: 999;
        }

        .vip-icon-box {
          position: relative;
          overflow: hidden;
          bottom: 0px;
          width: 100%;
          height: 100%;
          position: absolute;
        }

        @keyframes moveUp {
          from {
            bottom: -69px;
            /* 动画开始位置 */
          }

          to {
            bottom: -39px;
            /* 动画结束位置 */
          }
        }

        img {
          position: absolute;
          width: 120px;
          height: 120px;
          right: -40px;
          bottom: -42px;
          animation: moveUp-96b88790 0.3s ease-in-out forwards;
          filter: blur(2px);
        }

        .vip-name {
          font-size: 16px;
          font-weight: 600;
        }

        .vip-time {
          font-size: 12px;
        }

        .vip-price {
          position: relative;
          z-index: 1;
          height: 36px;
          font-size: 26px;
          font-family: Gilroy-Bold;
          font-weight: 700;
          color: #222;
          line-height: 36px;
          text-align: center;

          .syc {
            font-size: 12px !important;
          }
        }

        .product-tag {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: -1px;
          top: -4px;
          transform: translateY(-50%);
          height: 18px;
          padding: 0 6px;
          background-image: linear-gradient(108deg, #ff3700 0%, #ff9417 100%);
          border-radius: 4.67px 4.67px 4.67px 0;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 700;
          color: #fff;
          z-index: 22;
        }
      }

      .amount:last-child {
        margin-right: 0 !important;
      }

      .foot-box {
        overflow: hidden;
        bottom: 0px;
        width: 100%;
        height: 100%;
        position: absolute;
      }

      .foot {
        position: absolute;
        bottom: 0;
        width: 100%;
        font-size: 12px;
        background: #f8a467;
        padding: 2px 0px;
        border-radius: 0 0 4px 4px;
      }

      .foot-0 {
        background: rgba(248, 164, 103, 0.8);
      }

      .foot-1 {
        background: rgba(230, 191, 137, 0.8);
      }

      .foot-2 {
        background: rgba(44, 66, 184, 0.8);
      }
    }

    .vip-pay-qr-code {
      display: flex;
      margin-top: 10px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 20px;
      background: #fff;

      .vip-pay-qr-code-img {
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        min-width: 142px;
        height: 160px;
        margin-right: 13px;
        border: 1px solid #eee;
        border-radius: 4px;
        overflow: hidden;
        width: 10%;

        .pay-tip {
          margin-top: -10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 600;
        }

        .qr-code {
        }

        .ag-mask {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #fff;
          background-image: url("@/assets/img/qr-code-mask.png");
          background-size: 100% 100%;
          z-index: 100;

          p {
            font-family: PingFangSC-Medium;
            font-size: 12px;
            color: #454545;
            text-align: center;
            font-weight: 500;
            margin: 0;
          }

          .ag-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            height: 28px;
            padding: 0 9px;
            background: #1f2026;
            border-radius: 4px;
            font-family: PingFangSC-Medium;
            font-size: 12px;
            color: #fff;
            text-align: center;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }

      .vip-pay-qr-code-price {
        .pay-price-info {
          display: flex;
          align-items: baseline;
          height: 49px;
          margin-top: 80px;
          font-size: 16px;
          font-weight: bold;
          color: #222;
        }

        .pay-price {
          display: flex;
          align-items: flex-end;
          min-width: 57px;
          height: 49px;
          margin-left: 9.35px;
          padding-right: 5px;
          font-family: Gilroy-Bold;
          font-size: 36px;
          font-weight: 700;
          color: #ff5b16;
          line-height: 31px;
        }
      }
    }
  }
}
</style>
